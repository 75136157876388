<template>
  <h3>Data Visualization Masterclass</h3>
  <p>This online-only training event will occur prior to the <a href="/2022">VIZBI 2022</a> conference. Over two half-day sessions,
    the Masterclass provides a tour-de-force of state-of-the-art methods in data visualization, taught by internationally recognized
    experts. The event is designed for anyone working with large, complex datasets. This includes scientists, programmers,
    communicators, and designers.</p>
  <img src='@/assets/years/2022/Masterclass/img/banner/low_res.jpg' />
  <p>The course covers advanced data visualization methods that can be used to create insightful visual representations of complex
    data, and to create compelling visualizations for communication. These methods rely on principles of human visual perception,
    as well a knowledge of modern techniques and tools. Unfortunately, there are currently very few opportunities to receive
    training in these newly emerging methods.</p>
  <p>To address this, we designed the Data Visualization Masterclass as an eight-hour event to provide participants with an up-to-date
    overview of current DataVis methods, as well as the opportunity to connect with &#8212; and learn directly from &#8212;
    leading DataVis practioners.</p>
  <p>If you have further questions about the Masterclass, please write to <Email to="vizbi-contact"/>.</p>

  <h4>Preliminary program:</h4>
  <table style="text-align: left; width: 100%;">
    <colgroup>
      <col span="1" style="width: 25%;">
      <col span="1" style="width: 75%;">
    </colgroup>
    <tr>
      <th>Monday, March 14</th>
      <th />
    </tr>
    <tr>
      <td>8:30am - 10:00pm PDT</td>
      <td>Data visualization principles and practices <em>(<span id="topic-1"><a href="https://au.linkedin.com/in/mandala" target="_blank">Seán O'Donoghue</a></span>)</em><br>
        <span style="font-weight: 200;">Visualization is increasingly important for exploring complex datasets; but many of us struggle to create clear and effective visualisations, often assuming we lack natural talent. To address this, you'll learn principles, methods, and tools used to create exemplary visualizations of biological data. You'll also learn how surprising limitations in visual perception, revealed by optical illusions, can teach us methods to avoid. Although primarily focused on 2D data, we'll also cover visualization of 3D and multidimensional data.</span>
      </td>
    </tr>
    <tr>
      <td>10:00am - 10:30pm PDT</td>
      <td>Meet-the-speaker (optional) + coffee break</td>
    </tr>
    <tr>
      <td>10:30am - 12:00pm PDT</td>
      <td>Eye for design, mind for data: in search of balance <em>(<span id="topic0"><a href="http://mkweb.bcgsc.ca" target="_blank">Martin Krzywinski</a></span>)</em><br>
        <span style="font-weight: 200;">The goal of data visualization and information graphics is to reveal patterns and explain concepts with the minimum of fuss. You'll learn how to apply data visualization principles from Sean's class and borrow from key concepts in design to resuscitate confused, muddled and opaque figures. Through a large number of redesign case studies, you'll see how to practically apply ideas from Gestalt theory, typography, color, alignment, and composition. Submit graphics for redesign (PDF/AI/EPS) to <a href="mailto:martink@bcgsc.ca">martink@bcgsc.ca</a></span>.
      </td>
    </tr>
    <tr>
      <td>12:00pm - 12:30pm PDT</td>
      <td>Meet-the-speaker (optional) + coffee break</td>
    </tr>
  </table>
  <table style="text-align: left; width: 100%;">
    <colgroup>
      <col span="1" style="width: 25%;">
      <col span="1" style="width: 75%;">
    </colgroup>
    <tr>
      <th>Tuesday, March 15</th>
      <th />
    </tr>
    <tr>
      <td>8:30am - 10:00pm PDT</td>
      <td>Data visualization with WebGL <em>(<span id="topic1"><a href="https://danielhaehn.com" target="_blank">Daniel Haehn</a></span>)</em><br>
        <span style="font-weight: 200;">WebGL allows access to a computer's GPU in the web browser, and developers can create immersive and high-quality graphics using this API. You will learn the fundamental concepts of the 3D rendering pipeline and how frameworks with different complexities enable WebGL development. We will then create multiple scientific visualizations that you can share with your friends using web links.</span>
      </td>
    </tr>
    <tr>
      <td>10:00am - 10:30pm PDT</td>
      <td>Meet-the-speaker (optional) + coffee break</td>
    </tr>
    <tr>
      <td>10:30am - 12:30pm PDT</td>
      <td>Break</td>
    </tr>
    <tr>
      <td>12:30am - 2:00pm PDT</td>
      <td>Data storytelling via animation <em>(<span id="topic2"><a href="https://www.wehi.edu.au/people/drew-berry" target="_blank">Drew Berry</a></span>)</em></td>
    </tr>
    <tr>
      <td>2:00pm - 2:30pm PDT</td>
      <td>Meet-the-speaker (optional) + coffee break</td>
    </tr>
  </table>
  <h4>Image credits:</h4>
  <p>Benedetta Frida Baldi</p>
</template>

<script>
  import Email from '@/components/Email.vue';
  export default {
    components: { Email },
  };
</script>

<style scoped>
  img {
    width: 100%;
  }
</style>
