  var time_zone = {
    2010: 'Europe/Berlin',
    2011: 'America/New_York',
    2012: 'Europe/Berlin',
    2013: 'America/New_York',
    2014: 'Europe/Berlin',
    2015: 'America/New_York',
    2016: 'Europe/Berlin',
    2017: 'Australia/Sydney',
    2018: 'America/New_York',
    2019: 'Europe/Berlin',
    2020: 'America/Los_Angeles',
    2021: 'Europe/Berlin',
    2022: 'America/Los_Angeles',
    2023: 'Europe/Berlin'
  };

  export default time_zone;
