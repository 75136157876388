<!-- Copyright: Sean I. O'Donoghue -->

<template>
  <!-- temp disabled -->
  <input
    style="display:none;"
    ref="input"
    v-model="text"
    title="Site Search"
    @keyup.enter="search(text)"
  >
  <!-- Was: v-on: keyup.enter -->
  <!-- Was: v-on: keyup.enter - https://stackoverflow.com/questions/45369553 -->
</template>

<script>
  // https://cse.google.com/
  export default {
    name: 'SearchBox',
    // props: ['site'], - used in old (not working) version
    data() {
      return {
        text: ''
      };
    },
    methods: {
      search(str) {
        console.log(str);
        if (str) {
          // https://developers.google.com/custom-search/docs/element
          return this.$router.push('/Search?q=' + encodeURIComponent(str));
          //window.open(['https://vizbi.org/Search/?q=search', encodeURIComponent(str)].join(''));
        } else {
          this.$refs.input.focus();
        }
      }
    }
  };
</script>

<style scoped>
  input {
    width: 100%;
    box-sizing: border-box;
    /* 'border-box' forces input width to include padding */
    /* https://stackoverflow.com/questions/5219175 */
    margin-top: 0.5vw;
    font-size: 12px;
    font-weight: 400;
    outline: none;
    height: 22px;
    line-height: 22px;
    text-align: left;
    border-radius: 6px;
  }
</style>
