<!-- Copyright: Sean I. O'Donoghue -->

<template>
  <div class="sidebar">
    <div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen"></div>
    <transition name="slide">
      <div v-if="isPanelOpen" class="sidebar-panel">
        <ul>
          <li v-for="view in Object.keys(views)" :key="view">
            <router-link
              :to="'/' + view.replace('Home', '')"
              :class="{ selected: views[view].selected }"
            >
              {{ views[view].showAs }}
            </router-link>
          </li>
          <li>-------------------</li>
          <li>
            <router-link to='/Mail'>Join Mailing List</router-link>
          </li>
          <li>-------------------</li>
          <li v-for="year in Object.keys(years).reverse()" :key="year">
            <router-link
              :to="'/' + year"
              :class="{ selected: years[year] }"
            >
              {{ year }}
            </router-link>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>
<script>
  import store from '@/store';
  export default {
    props: {
      views: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        years: {
          '2023': false,
          '2022': false,
          '2021': false,
          '2020': false,
          '2019': false,
          '2018': false,
          '2017': false,
          '2016': false,
          '2015': false,
          '2014': false,
          '2013': false,
          '2012': false,
          '2011': false,
          '2010': false
        }
      }
    },
    methods: {
      closeSidebarPanel() {
        return this.$store.commit('toggleNav');
      },
      updateSelectionState() {
        for (var year of Object.keys(this.years)) {
          if (
            this.$route.params.year &&
            this.$route.params.year === year
          ) {
            // highlight the currently selected year
            this.years[year].selected = true;
            this.log(`Selected year: ${year}`);
          } else {
            // ensure other years are not highlighted
            this.years[year].selected = false;
          }
        }
      }
    },
    computed: {
      isPanelOpen() {
        return this.$store.state.isNavOpen;
      }
    },
    watch:{
      $route (to, from){
        this.$store.commit('closeNav');
      }
    }
  }
</script>
<style scoped>
  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.2s ease;
  }

  .slide-enter,
  .slide-leave-to {
    transform: translateX(100%);
    transition: all 150ms ease-in 0s
  }

  .sidebar-backdrop {
    background-color: rgba(19, 15, 64, .4);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 998;
  }
  .sidebar-panel {
    overflow-y: auto;
    background-color: white; /* #130f40 */
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    z-index: 999;
    padding: 1rem 20px 2rem 20px;
    width: 130px; /* 300px */
    /* https://stackoverflow.com/questions/5115427 */
    box-shadow: -5px 0 3px -2px rgba(140, 140, 140, .5);
  }
  .medium-screen .sidebar-panel, .wide-screen .sidebar-panel {
    width: 140px; /* 300px */
  }
  /* styles below are from NavigationPrimary.vue */
  ul {
    flex-basis: 35%;
    /* Was; display: block;     display: flex; */
    list-style-type: disc;
    margin-top: 14px;
    margin-bottom: 28px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px;
    text-align: right;
    /* text-shadow: rgb(0, 0, 0) 0px 0px 6px; change from from NavigationPrimary.vue*/
  }
  li {
    display: list-item;
    /* was 1.5em - https://css-tricks.com/confused-rem-em/*/
    line-height: 1.3rem;
    /* was: 1.5em */
    font-weight: 700;
    letter-spacing: 0.03em;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    text-align: right;
    color: #D5D5D5; /* this is the only changes from NavigationPrimary.vue */
    /* text-shadow: rgb(0, 0, 0) 0px 0px 6px; change from from NavigationPrimary.vue*/
  }

  /* Mobile first - very-narrow-screen */
  .very-narrow-screen li {
    font-size: 13px; /* was: 0.8rem 18px */
  }
  /* narrow-screen */
  .narrow-screen li {
    font-size: 14px; /* was: 0.8rem 18px */
  }
  /* medium-screen */
  .medium-screen li {
    font-size: 15px; /* was: 0.8rem 18px */
  }
  /* wide-screen */
  .wide-screen li {
    font-size: 16px; /* was: 0.8rem 18px */
  }

  /* color without hover */
  a {
    color: #C1C1C1; /* this is the only changes from NavigationPrimary.vue */
    text-decoration: none;
  }
  /* color while hovering or selected */
  .america a:hover, .america a.selected  {
    color: var(--text-color-america);
  }
  .europe a:hover, .europe a.selected {
    color: var(--text-color-europe);
  }
  .australia a:hover, .australia a.selected {
    color: var(--text-color-australia);
  }
</style>
