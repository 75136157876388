import {createStore} from 'vuex';

const store = createStore({
  state: {
    // Next meeting, or previous if prior to announcement
    // easier if this is a string for consistency with $route.parms.year
    currentYear: '2023',
    isNavOpen: false,
  },
  mutations: {
    setIsNavOpen(state, yesno) {
      state.isNavOpen = yesno;
    },
    toggleNav(state) {
      state.isNavOpen = !state.isNavOpen;
    },
    closeNav(state) {
      state.isNavOpen = false;
    },
  },
});

export default store;
