<!-- Copyright: Sean I. O'Donoghue -->

<template>
  <h2>Videos</h2>
  <div>
    <Dropdown placeholder='Year' :options="years" :selected="year" @update="filterByYear" />
    <Dropdown placeholder='Session' :options="sessions" :selected="session" @update="filterBySession" />
    &nbsp;&nbsp;
    <Button v-if="year || session" @click="showAll">Show All</Button>
  </div>
  <Video
    v-if="$route.params.vimeoHash"
    :thumbnails="thumbnails"
    :showcases="showcases"
  />
  <div v-else>
    <!-- eslint-disable-next-line vue/no-template-shadow -->
    <span v-for="showcase in Object.keys(filteredShowcases)" :key="showcase">
      <div v-if="(showcases[showcase]['privacy'] === 'anybody' || running_locally) && (year === '' || showcase.includes(year))">
        <h4>{{ showcase }}</h4>
        <figure
          v-for="vimeoHash in Object.keys(filteredShowcases[showcase]['videos'])"
          :key="vimeoHash"
        >
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <img
            :src="smallThumbnail(showcases, showcase, vimeoHash)"
            @click.prevent="setVideo(showcase, vimeoHash)"
          >
          <div v-if="showcases[showcase]['videos'][vimeoHash]['privacy'] === 'nobody'" class="child">
            Register or login to access
          </div>
          <figcaption>
            <div class="name"> {{ showcases[showcase]['videos'][vimeoHash].name }} </div>
            <Tag
              :label="showcases[showcase]['videos'][vimeoHash].tag"
              :selected="session"
              @update="filterBySession"
            />
            <Tag
                :label="showcase.match(/\d+/)[0]"
                :selected="year"
                @update="filterByYear"
              />
          </figcaption>
        </figure>
      </div>
    </span>
  </div>
</template>

<script>
  import Button from '/src/components/Button';
  import Dropdown from '/src/components/Dropdown';
  import Tag from '/src/components/Tag';
  import showcases from '/src/assets/videos/showcases.json';
  import stringify from 'json-stringify-safe';
  import Video from "/src/components/Video";
  export default {
    components: {
      Button,
      Dropdown,
      Tag,
      Video,
    },
    data() {
      return {
        showcases: showcases, // assign json to data property
        thumbnails: {},
        videoList: showcases,
        registered_events: {},
        year: '', // empty string = all years
        session: '', // empty string = all session
      };
    },
    computed: {
      running_locally() {
        if (window.location.host.includes('vizbi.org')) {
          // published on vizbi.org or dev.vizbi.org
          return false
        } else {
          // otherwise, it is running locally
          return true
        }
      },
      years() {
        let years = []; // 'All'
        Object.keys(this.showcases).forEach(function(year) {
          // Accumulate list of years
          year = year.split(' ')[1];
          if (!years.includes(year)) {
            years.push(year);
          }
        });
        return years
      },
      sessions() {
        let sessions = []; // 'All'
        var _this = this;
        Object.keys(this.showcases).forEach(function(year) {
          // Accumulate list of sessions
          Object.keys(_this.showcases[year].videos).forEach(function(hash) {
            let session = _this.showcases[year].videos[hash].tag;
            if (session && !sessions.includes(session)) {
              sessions.push(session);
            }
          });
        });
        return sessions
      },
      filteredShowcases() {
        this.log(`filterShowcases(${this.session}, ${this.year})`);
        var _this = this;
        var filteredShowcases = {};
        var value;
        if (this.session) {
          // Retrieve list of session tags for the dropdown menu
          Object.keys(_this.showcases).forEach(function(year) {
            Object.keys(_this.showcases[year].videos).forEach(
              function(hash) {
                value = _this.showcases[year].videos[hash].tag;
                if (value === _this.session) {
                  if (!filteredShowcases[year]) {
                    filteredShowcases[year] = {};
                    filteredShowcases[year].videos = {};
                  }
                  filteredShowcases[year].videos[hash] = _this.showcases[year].videos[hash];
                }
              }
            );
          });
        } else if (this.year) {
          Object.keys(_this.showcases).forEach(function(item) {
            value = item.split(' ')[1];
            if (value === _this.year) {
              filteredShowcases[item] = _this.showcases[item];
            }
          });
        } else {
          filteredShowcases = this.showcases;
        }
        this.log(`videoList = ${Object.keys(_this.showcases).length}`);
        this.log(`filteredShowcases = ${Object.keys(filteredShowcases).length}`);
        return filteredShowcases;
      }
    },
    async mounted() {
      this.log("Videos mounted()");
      if (this.$route.params.vimeoHash) {
        await this.get_showcase(this.$route.params.vimeoHash);
      }
    },
    methods: {
      log(message) { // eslint-disable-line
        if (process.env.NODE_ENV === 'development') {
          //console.log(message); // uncomment to show logs from this component
        }
      },
      smallThumbnail(filteredShowcases, showcase, vimeoHash) {
        // retrieves the 2nd thumbnail image for a Vimeo video
        if (filteredShowcases[showcase]) {
          return filteredShowcases[showcase]['videos'][vimeoHash]['pictures']['base_link'] + filteredShowcases[showcase]['videos'][vimeoHash]['pictures']['sizes'][1];
        }
      },
      filterByYear(year) {
        this.log(`change selected year to: ${year}`);
        if (year === 'All' || year === '') {
          this.showAll();
        } else {
          this.year = year;
          this.session = '';
        }
        // automatically triggers computed > filteredShowcases();
      },
      filterBySession(session) {
        this.log(`change selected session to: ${session}`);
        if (session === 'All' || session === '') {
          this.showAll();
        } else {
          this.session = session;
          this.year = '';
        }
        // automatically triggers computed > filteredShowcases();
      },
      showAll() {
        this.year = '';
        this.session ='';
        // automatically triggers computed > filteredShowcases();
        if (this.$route.params.vimeoHash) {
          // if viewing a video, switch back to videos page
          this.$router.push({name: 'Videos'});
        }
      },
      setVideo(showcase, vimeoHash) {
        // called when the user clicks on a thumbnail to open a video
        this.log(`showcase=${showcase}, vimeoHash=${vimeoHash}`);
        this.$router.push({ // was 'history.'
          name: 'Videos',
          params: {'vimeoHash': vimeoHash}
        });
        //this.showcase = showcase;
        //this.vimeoHash = vimeoHash;
      },
      async get_showcase(vimeoHash) {
        this.log(`get_showcase(${vimeoHash})`);
        let showcase; // local variable
        if (!vimeoHash) {
          console.warn(`vimeoHash not found`);
        } else if (!this.showcases) {
          console.warn(`this.showcases not found`);
        } else if (!Object.keys(this.showcases)) {
          console.warn(`No keys in this.showcases`);
        } else if (Object.keys(this.showcases).length === 0) {
          console.warn(`No keys in this.showcases`);
        } else {
          this.log(`About to check ${Object.keys(this.showcases).length} filteredShowcases`);
          for (showcase in this.showcases) {
            this.log(`Checking showcase: ${showcase}`);
            if (this.showcases[showcase]['videos'][vimeoHash]) {
              this.log(`Found showcase = ${showcase}`);
              let match = / (\d+)$/.exec(showcase);
              if (match[0]) {
                this.log(`Set year: ${match[0]}`);
                this.year = match[0];
              }
              this.session = this.showcases[showcase]['videos'][vimeoHash]['tag'];
              this.log(`Set session: ${this.session}`);
              this.thumbnails = this.showcases[showcase]['videos'][vimeoHash]['pictures'];
              break; // end for loop
            } // otherwise, keep iterating over filteredShowcases
          }
          //return this.showcase;
        }
        if (!showcase) {
          console.warn(`Missing needed meta data; returning to /Videos page`);
          this.$router.push('/Videos');
        }
      }
    }
  };
</script>

<style scoped>
  a {
    margin-right: 20px;
  }
  figure {
    position: relative;
    cursor: pointer;
    display: inline-table;
    /* https://stackoverflow.com/questions/30686191 */
    margin: 0px;
    padding: 0px;
    /* ideally, automatically switch to hi-res thumbnails on large screens */
    width: 200px;
    height: 150px;
    border-spacing: 8px;
  }
  figcaption button {
    font-size: 9.9px;
  }
  /* https://www.codegrepper.com/code-examples/css/css+position+absolute+center+vertical+horizontal */
  .child {
    position: absolute;
    width: 160px;
    height: 50px;
    line-height: 50px;
    font-size: 12px;
    background-color: #FFFFFF;
    left: 50%;
    top: 50%;
    text-align: center;
    border-radius: 30px;
    transform: translate(-50%, 20%);
    opacity: 30%;
  }
  .hide {
    display: none;
  }
  .show {
    display: inline;
    font-weight: 900;
  }
</style>
