<template>
  <div>
    <p>The 12<sup>th</sup> international meeting on Visualizing Biological Data will take place March 16&ndash;18, 2019.</p>
    <p>
      <strong>Update Jan 15, 2019:</strong> Due to ongoing uncertainties around Omicron, we have made the decision with our
      partners at USC's <a href="https://dornsife.usc.edu/bridge-institute/about" target="_blank">Bridge Institute</a> that
      VIZBI 2019 will now run to a fully virtual meeting.
    </p>
    <HeroImage />
    <p>VIZBI 2019 will feature talks from 21 world-leading researchers showcasing visualizations transforming how life scientists
      view data, and driving key advances in molecular biology, systems biology, biomedical science, and ecology.</p>
    <p>The conference brings together a diverse community, including bioinformaticians, data scientists, computer scientists,
      and experimentalists, as well as medical illustrators, graphic designers, and graphic artists.</p>
    <!--<p>All participants will have the opportunity to present a <a href="Posters/">poster and lightning talk</a> describing their work.</p> -->
    <p>All participants will have the opportunity to present a poster and lightning talk describing their work. To see examples
      from previous VIZBIs, please take a look at the <router-link to="/Posters/2021">VIZBI poster collection</router-link>.</p>
    <!--				<p>If you are interested in promoting your organization or professional society, please contact us to discuss <a href="Sponsors/">sponsorship options</a>.</p> -->
    <p>
      <!--More details will be announced over the next few weeks. -->To receive updates about VIZBI, we encourage you to join the <router-link to="/Mail">VIZBI mailing list</router-link>.
    </p>
    <!-- <a href="http://www.iscb.org/" rel="nofollow"><img src="Sponsors/img/ISCB.jpg" alt="ISCB" title="ISCB" align="absmiddle" /></a> -->
    <h3>Key Dates</h3>
    <table>
      <colgroup>
        <col span="1" style="width: 40%;">
        <col span="1" style="width: 60%;">
      </colgroup>
      <tr>
        <td><b>Feb 28</b> <span>(11pm PST)</span>:</td>
        <td>Deadline for <router-link to="/2019/Registration">early-bird registration</router-link></td>
      </tr>
      <tr>
        <td><b>Mar 4</b> <span>(11pm PST)</span>:</td>
        <td>Deadline for <router-link to="/2019/Posters">Poster and Art &amp; Biology</router-link> upload</td>
      </tr>
      <tr>
        <td><b>Mar 14-15</b>:</td>
        <td><router-link to="/2019/Masterclass">Data Visualization Masterclass</router-link></td>
      </tr>
      <tr>
        <td><b>Mar 16-18</b>:</td>
        <td><router-link to="/2019/Program">VIZBI conference</router-link></td>
      </tr>
      <tr>
        <td><b>Mar 18-20</b>:</td>
        <td>Workshop on <router-link to="/2019/Workshop">Understanding COVID Data</router-link></td>
      </tr>
    </table>
  </div>
</template>

<script>
  import HeroImage from '@/components/HeroImage.vue';
  export default {
    components: { HeroImage }
  };
</script>

<style scoped>
  table {
    /* width: 100%; Better? */
  }
  b {
    font-weight: 500;
  }
  span {
    font-weight: 200;
  }
</style>
