<!-- Copyright: Sean I. O'Donoghue -->

<template>
  <keep-alive :key="selectedYear">
    <Background v-if="selectedYear" :selected-year="selectedYear" />
  </keep-alive>
  <div v-if="selectedYear" id="wrapper" :class="[screenWidthClass, continent]">
    <!-- main color switch -->
    <Header :selected-year="selectedYear" />
    <div id="main">
      <!--   -->
        <router-view v-slot="{ Component }" :key="$route.fullPath" >
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view>
      <Warning />
    </div>
  </div>
</template>

<script>
  import 'normalize.css'; // https://stackoverflow.com/questions/42119878
  import Header from '@/components/Header.vue';
  import Background from '@/components/Background.vue';
  import Warning from '@/components/Warning.vue';
  // load vizbi history - https://stackoverflow.com/questions/43608457
  let time_zone = require('@/assets/history.js').default;
  export default {
    name: 'App',
    components: {
      Header,
      Background,
      Warning
    },
    data() {
      return {
        screenWidthClass: 'very-narrow-screen', // Mobile first
        selectedYear: '', // initial value, before $route is set up
        continent: '' // will be set depending on selectedYear
      };
    },
    watch: {
      $route(route) {
        // runs when $route changes
        // local variable 'route' will be set to '$route'
        this.updateSelectedYear(route);
      }
    },
    mounted() {
      this.log(`process.env.NODE_ENV = ${process.env.NODE_ENV}`);
      this.log(`process.env.VUE_APP_API = ${process.env.VUE_APP_API}`);
      let updateScreenWidthClass = () => {
        if (window.innerWidth < 480) {
          this.screenWidthClass = 'very-narrow-screen'; // Mobile first
        } else if (window.innerWidth < 580) {
          this.screenWidthClass = 'narrow-screen'; // Mobile first
        } else if (window.innerWidth < 768) {
          this.screenWidthClass = 'medium-screen'; // Mobile first
        } else {
          this.screenWidthClass = 'wide-screen'; // with > 768
        }
      };
      updateScreenWidthClass(); // run now
      window.onresize = updateScreenWidthClass; // run upon resize
    },
    methods: {
      log(message) {                                      // eslint-disable-line
        if (process.env.NODE_ENV === 'development') {
          //console.log(message); // uncomment to show logs from this component
        }
      },
      methodFunction(route) {
        this.log(`methodFunction(${JSON.stringify(route)}) called`);
        this.myPath = route.path;
      },
      updateSelectedYear: function(route) {
        // called once on first page load, then once each time $route changes
        this.log(`App()//this.selectedYear = '${this.selectedYear}'`);
        this.log(`App()//route.params.year = '${route.params.year}'`);
        this.log(`App()//this.$store.state.currentYear = ${this.$store.state.currentYear}`);
        let newSelectedYear = route.params.year || this.$store.state.currentYear;
        //this.log(`App()//selectedYear = ${selectedYear}`)
        if (this.selectedYear !== newSelectedYear) {
          this.log(`selectedYear changed from '${this.selectedYear}' to '${newSelectedYear}'`);
          let newContinent = time_zone[newSelectedYear].match(/^[^/]*/)[0].toLowerCase();
          if (this.continent !== newContinent) {
            this.log(`continent changed from '${this.continent}' to '${newContinent}'`);
            this.continent = newContinent;
          }
          this.selectedYear = newSelectedYear;
        }
      },
      updateScreenWidthClass(self) {
        this.log('updateScreenWidthClass()');
        if (window.innerWidth < 480) {
          self.screenWidthClass = 'very-narrow-screen'; // Mobile first
        } else if (window.innerWidth < 580) {
          self.screenWidthClass = 'narrow-screen'; // Mobile first
        } else if (window.innerWidth < 768) {
          self.screenWidthClass = 'medium-screen'; // Mobile first
        } else {
          self.screenWidthClass = 'wide-screen'; // with > 768
        }
      }
    }
  };
</script>

<style> /* not 'scoped' = CSS applies to all components */
  /* regularizes font-size accross browsers */
  /* https://stackoverflow.com/questions/51806956 */
  :root {
    --text-color-america: #f4b;
    --text-color-europe: #3c9;
    --text-color-australia: #a559ad;
    --smallest-screen-width: 460px;
  }
  html {
    font-size: 16px;
    /* https://stackoverflow.com/questions/48240969 */
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    /* from vuejs default: keep?  */
    -moz-osx-font-smoothing: grayscale;
    /* from vuejs default: keep?  */
    /* hyphens: auto; */
  }
  #app {
    /* added to <template> */
    text-align: center;
    /* used to center #header and #page */
    margin: 0;
    padding: 0;
  }
  #wrapper {
    /* https://help.uberflip.com/hc/en-us/articles/360019190171 */
    display: inline-block;
    text-align: left;
    /* set back to default */
    min-width: 150px;
    max-width: 890px; /* max-width: 100vw; */
  }
  #main {
    /* Was: page */
    background-color: white;
    font-size: 1rem;
    min-height: 70vh;
    /* was 1.5em */
    /* was: 1.5em */
    padding: 20px 15px 10px 15px;
  }
  .very-narrow-screen #main {
    padding: 10px 15px 10px 15px;
  }
  h2 {
    /* Shows number of year within each Year page */
    font-size: 1.8em;
    font-weight: bold;
  }
  h3 {
    /* subsections within a year */
    margin-top: 12px;
    margin-bottom: 8px;
    font-size: 1.8em;
    font-weight: bold;
  }
  .very-narrow-screen h3 {
    margin-top: 6px;
  }
  h4 {
    /* sub-subsections - not linkable */
    font-size: 1.2em;
    margin-top: 19px;
    margin-bottom: 8px;
    font-weight: bold;
  }
  .very-narrow-screen h4 {
    font-size: 5vw;
    margin-top: 6vw;
    margin-bottom: 4vw;
  }
  h5 {
    /* highlighted text - not linkable */
    font-size: 1.1em;
    font-weight: bold;
    margin: 0px;
    padding: 0px;
  }
  p {
    margin: 8px 0px 8px 0px;
    line-height: 1.5rem;
  }
  a {
    color: #369;
    text-decoration: none;
  }
  a:hover {
    color: #69b;
    text-decoration: underline;
  }
  a:hover h3 {
    text-decoration: underline;
  }
  a img {
    border: 0px none;
  }
  /* https://stackoverflow.com/questions/8315690 */
  button {
    /* font-size: 16px; */
    background-color: #EFEDF1;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    border: 1px solid rgba(207, 207, 207, 0.2);
    display: inline-block;
    cursor: pointer;
    padding: 10px 6px;
    /* margin-top: 12px;
    margin-bottom: 18px;
    line-height: 10px; */
    text-decoration: none;
  }
  button[disabled] {
    /* pointer-events: none; disabled cause it hides title */
    cursor: default;
    color: #929292;
    background-color: #F5F3F7;
    border: 1px solid rgba(60, 60, 60, 0.2);
  }
  input {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    border: 0.5px solid rgba(94, 94, 94, 0.6);
    display: inline-block;
    color: black;
    padding: 7px 24px;
    margin-top: 12px;
    margin-bottom: 18px;
    line-height: 10px;
    text-decoration: none;
    /* width: 300px; */
  }
  input::placeholder {
      color: #929292;
  }
  input:focus::placeholder {
    color: transparent; /* hide placeholder text when user clicks in field */
  }
  input:focus {
    border: 0.5px solid var(--text-color-europe);
    outline: var(--text-color-europe);
  }
  input:disabled {
    cursor: default;
    color: #929292;
    background-color: #F5F3F7;
  }
  /* Don't know why, but the following are needed for list defaults */
  ul>li {
    list-style-type: disc;
  }
  ol>li {
    list-style-type: decimal;
  }
</style>
