<!-- Copyright: Sean I. O'Donoghue -->

<template>
  <div id="tweets">
    <a class="twitter-timeline" href="https://twitter.com/vizbi?ref_src=twsrc%5Etfw">Tweets by vizbi
    </a>
    <!--<a class="twitter-timeline" data-height="1000" href="https://twitter.com/vizbi/timelines/861506820359069696?ref_src=twsrc%5Etfw">VIZBI Tweets - Curated tweets by vizbi</a>-->
  </div>
</template>

<script>
  export default {
    name: 'TwitterFeed',
    beforeRouteLeave () {
      // this.observer.disconnect(); // breaks the router; no idea why was here
    },
    data() {
      return {
        observer: {}
      };
    },
    async beforeMount() {
      if (this.log) {
        this.log('TwitterFeed beforeMount()');
      }
      const twitterScript = document.createElement('script');
      twitterScript.async = true;
      await twitterScript.setAttribute('src', 'https://platform.twitter.com/widgets.js');
      await document.head.appendChild(twitterScript);
      // https://stackoverflow.com/questions/45047126/
    },
    mounted() {
      this.log('TwitterFeed mounted()');
      //this.clear_console();
    },
    methods: {
      log(message) {                                      // eslint-disable-line
        if (process.env.NODE_ENV === 'development') {
          //console.log(message); // uncomment to show logs from this component
        }
      },
      clear_console() {
        // https://stackoverflow.com/questions/15657686
        // Specify a target to observe
        let target = document.querySelector('#tweets');
        // Create an observer object
        this.observer = new MutationObserver(function() {
          setTimeout(
            console.clear, // clear twitter console messages
            500 // wait a bit
          );
        });
        // start observing the selected properties of the target
        this.observer.observe(target, {
          attributes: true,
          childList: true,
          characterData: true
        });
      }
    }
  };
</script>

<style scoped>
</style>
