<template>
  <div>
    <p>VIZBI 2022 will use the software platforms listed below to facilitate engagement, communication, and networking for attendees of this virtual event.</p>

    <p>Talks will delivered via a live stream, then later available as on-demand videos.</p>

    <p><a href="https://slack.com/" target="_blank">Slack</a> will be available for use by all of the attendees of this event to collaborate, communicate and network. All participants will receive information on how to join shortly before the conference. <!-- Please download and read the <a href="VIZBI_Slack_guide.pdf" target="_blank">VIZBI Slack Guide</a>.-->
    </p>

    <p><a href="http://www.pheedloop.com/" target="_blank">Pheedloop</a> will host all live streamed talks, speed networking, and breakout sessions. This platform utilizes a <a href="https://zoom.us/download" target="_blank">Zoom</a> integration. We recommend using the latest version of Chrome to access Pheedloop, disabling ad blockers, enabling pop-ups, and checking your security protocols to ensure you are able to participate in all sessions. Should there be a issue with the connection for a session, attendees will be able to join directly through their local Zoom client.</p>

    <!-- will be used for all live streamed talks. In addition, Zoom will be used for several other aspects of the programme, such as speed networking and breakout sessions. To ensure you 
      are able to participate in these sessions please use the latest version of the Zoom client available for your device, rather than the web-based app -->

    <p><a href="https://gather.town/" target="_blank">Gather Town</a> will be used for most of the non-structured, social events, such as coffee and lunch breaks. This platform gives each participant an avatar and provides a range of mechanisms designed to facilitate interaction including one-on-one meetings and small group discussion.</p>

    <h4>Time zone</h4>
    <p>The programme is based on <a target="_blank" href="https://www.timeanddate.com/worldclock/meetingtime.html?day=14&month=3&year=2022&p1=137&p2=179&p3=136&p4=37&iv=0">Pacific Daylight Time (PDT)</a> and will be streamlined live; immediately
      afterwards, recordings of the talks will be available to all participants. To help those in different time zones, we are planning interactive sessions during the conference breaks that will provide multiple opportunities to engage with
      speakers as well as other conference participants.</p>

    <h4>Contacting speakers & participants</h4>
    <p>Questions to speakers can be asked during or after the talks in the live streaming platform. The session chair will moderate the questions and share them with the speakers. These can be answered either immediately following the presentation,
      or during 'meet-the-speaker' events that will run in the break following each session. You will also have the option to contact speakers (as well as other partcipants) via direct message.</p>

    <h4>Code of Conduct</h4>
    <p>VIZBI 2022 is hosted by the <a href="https://dornsife.usc.edu/bridge-institute/">Bridge Institute</a> at the <a href="http://www.usc.edu/">University of Southern California</a>, so participants need to follow USC's <a
      href="http://gwep.usc.edu/wp-content/uploads/2020/08/USC-Virtual-Code-of-Conduct-7-29-20-IPGC-FINAL.pdf"
    > Virtual Code of Conduct</a>.</p>
  </div>
</template>

<script>
export default({
  name: 'Index'
});
</script>
