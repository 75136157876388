<!-- Copyright: Sean I. O'Donoghue -->

<template>
  <button v-if="selected" class='selected'>
    <slot />
  </button>
  <button v-else :disabled="disabled" :title="title" :class="{'outstanding': outstanding}">
    <slot />
  </button>
</template>

<script>
  export default {
    name: "Button",
    props: {
      disabled: {type: Boolean, default: false},
      outstanding: {type: Boolean, default: false},
      selected: {type: Boolean, default: false},
      title: {type: String, default: ''},
    }
  };
</script>

<style scoped>
  button {
    padding: 0.3em 0.6em; /* top/bottom - left/right */
    margin: 0px;
    border-radius: 8px;
    border: 1px solid rgba(207, 207, 207, 0.2);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    text-align: left;
    text-shadow: none;
    color: #505050;
    background-color: #EFEDF1;
    cursor: pointer;
  }
  .america button:focus, .america button.outstanding {
    color: white;
    background-color: var(--text-color-america);
    border: 0.5px solid var(--text-color-america);
    opacity: 1;
  }
  .europe button:focus, .europe button.outstanding {
    color: white;
    background-color: var(--text-color-europe);
    /* border: 0.5px solid var(--text-color-europe); */
    opacity: 1;
  }
  .australia button:focus, .australia button.outstanding {
    color: white;
    background-color: var(--text-color-australia);
    border: 0.5px solid var(--text-color-australia);
    opacity: 1;
  }
  button[disabled], button[disabled]:focus, button[disabled].outstanding {
    cursor: default;
    color: rgb(200, 200, 200); /*  F6F5F8 B8B8BA  */
    background-color: #F5F3F7; /* F6F5F8  */
    border: 1px solid rgba(0, 0, 0, 0.01);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06);
  }
  button:hover {
    background-color: #D5D5D5;
  }
  button:hover.outstanding {
    opacity: 0.85;
  }
  a:hover:not(:focus) {
    color: #505050; /* when it has focus but not hover, back to normal */
  }
  button.selected {
    cursor: default;
    border: 1px solid rgba(207, 207, 207, 0);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0);
    text-shadow: none;
    font-weight: 700;
    background-color: transparent;
    letter-spacing: 0.03em;
    text-shadow: rgb(0, 0, 0) 0px 0px 6px;
    opacity: 1;
  }
  .america button.selected {
    color: var(--text-color-america);
  }
  .europe button.selected {
    color: var(--text-color-europe);
  }
  .australia button.selected {
    color: var(--text-color-europe);
  }
</style>
