<template>
  <div>
    <!-- begin image src with "./" so it's imported as a vue 'static asset' -->
    <!-- https://cli.vuejs.org/guide/html-and-static-assets.html#static-assets-handling -->
    <a href="https://dornsife.usc.edu/bridge-institute/" target="_blank">
      <img src="./img/Bridge-Gold+White.svg" alt="USC Bridge Institute" title="USC Bridge Institute">
    </a>
  </div>
</template>

<style scoped>
  img {
    /* May need some adjustments depending on aspect ratio etc */
    /* https://stackoverflow.com/questions/3029422 */
    max-width: 35%;
    vertical-align: middle;
    margin-bottom: 10px;
  }
</style>
