<template>
  <div id="wrapper">
    <!--#include virtual="/header.shtml" -->
    <div id="page" class="section">
      <div id="sidebaractive" title="contact" />
      <!--#include virtual="/2022/secondaryNav.html" -->
      <p>For inquiries relating to registration, please contact:</p>
      <Email to="vizbi-registration" outstanding=true />
      <p>For inquiries relating to the conference program or sponsorship opportunities, please contact:</p>
      <Email to="vizbi-contact" outstanding=true />
      <!-- <p>For inquiries related to venue logistics or transport, please contact:</p> <p class="outstanding" style="margin-left: 2.5em;"> <script src="/Scripts/email_bridge.js"></script> </p><p>For inquiries related to accommodation, please contact the <a href="https://vizbi.org/2022/Venue #accommodation">USC Hotel</a>.</p>-->
    </div>
  </div>
</template>

<script>
  import Email from '@/components/Email.vue';
  export default {
    components: { Email }
  };
</script>

<style scoped>
  .outstanding {
    font-weight: 700;
    font-size: 1.2em;
    margin-left: 48px;
  }
</style>
