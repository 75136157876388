<template>
  <div>
    <a name="top" />
    <table>
      <colgroup>
        <col span="1" style="width: 33%;"> <!--  -->
        <col span="1" style="width: 33%;">
        <col span="1" style="width: 33%;">
      </colgroup>
      <tr>
        <th v-for="event in events" :key="event">
          <!-- https://vuejs.org/guide/essentials/template-syntax.html -->
          {{ main(event) }}
          <span v-if="event.match(/VIZBI/)" class="wideContent"> workshop </span>
          <br class="narrowContent" />
          <span style="font-weight: 300;">{{ subtitle(event) }}</span>
        </th>
      </tr>
      <tr>
        <td v-for="event in events" :key="event" >
          <p><span class="wideContent">Registration </span>Fees:</p>
          <span class="event">
            <span class="arrows">
              <p v-for="category of categories" :key="category">
                {{ category }}:
              </p>
            </span>
            <span class="arrows">
              <p v-for="category of categories" :key="category" class="eventTitle">
                <RegistrationFee :fees="fees" :event="event" :category="category" :iscb_member="iscb_member"/>
              </p>
            </span>
          </span>
        </td>
      </tr>
      <tr>
        <td v-for="event of events" :key="event">
          <p><span class="wideContent">ISCB </span>Discount:</p>
          <small style="font-weight: 200;">ISCB members get 15% off<span class="wideContent"> registration</span>.</small><br />
          <small><router-link to="#ISCB">Learn more.</router-link></small><br />
          <!-- click > blur: https://stackoverflow.com/questions/67976183 -->
          <Button
            v-if="!iscb_member" :ref="event"
            @click.stop="$event.target.blur(); showDialog = true;"
            :title="`Click to apply the ISCB discount`" >
            Apply<span class="wideContent"> discount</span>
          </Button>
          <Button
            v-else
            :outstanding="true"
            :disabled="true">
            Applied
          </Button> <!-- <span class="wideContent">Discount </span> -->
        </td>
        <Iscb
          v-if="showDialog && !iscb_member"
          @emailValid="iscb_member = true"
          @hideDialog="showDialog = false" />
      </tr>
      <tr>
        <td v-for="event of events" :key="event">
          <small>Click the button below to <span class="wideContent">create an account and</span> finalise payment with <span v-html="tweak3(event)"></span>.</small>
        </td>
      </tr>
      <tr>
        <td v-for="event of events" :key="event">
          <Button
            :outstanding="true"
            @click="goToForm(event)"
            :title="`Click to register for ${tweak2(event)}`">
            <span class="wideContent">Register for </span>{{ tweak2(event) }}
          </Button>
        </td>
      </tr>
      <tr>
        <td v-for="date in ['14 February', '6 March', '']" :key="date">
          <small v-if="date">Registration closes: <b>{{date}}</b></small>
        </td>
      </tr>
    </table>
    <h4>Terms and conditions</h4>
    <p>VIZBI on-site registration fees include admission, conference materials, COVID-19 safety measures, meals and coffee breaks. Participants are expected to book and pay their own accommodation and travel expenses. Virtual registration fees include access to all of the talks (livestreamed and on demand) and facility to submit questions. NO visa support letters will be issued until payment of the registration fee is confirmed. For further details, please see the EMBL conference office <a href="https://www.embl.org/about/info/course-and-conference-office/information-for-participants/terms-and-conditions/" target="_blank" rel="noopener">Terms and Conditions</a>.</p>

    <h4>Confirmation and payment</h4>
    <p>Registration will be on a first-come first-served basis. Your place can only be confirmed after payment of the registration fee. If you are added to our waiting list, please consider taking advantage of our offerings to participate virtually.</p>
    <p>For on-site participants, types of payments accepted are international bank transfers and credit card payments. For virtual participants, we are only able to accept card payments. In exceptional cases we can accept bank transfers. Please <router-link to="Contact">contact us</router-link> for details.</p>

    <h4 id="ISCB"><a name="ISCB"></a>ISCB members discount</h4>
    <p>Members of the International Society for Computational Biology (<a href="http://iscb.org/">ISCB</a>) receive a 15% discount for all events. We encourage participants to consider joining ISMB to receive the discount, in addition to <a href="http://www.iscb.org/iscb-membership">other benefits</a>. To receive your discount, click the 'Apply<span class="wideContent"> discount</span>' button above, then enter the email address associated with your ISCB membership.</p>

    <h4 id="waivers"><a name="waivers"></a>Fee waivers</h4>
    <p>A limited number of registration fee waivers are available from <a href="https://embo.org" target="_blank" rel="noopener">EMBO</a> and the the EMBL Advanced Training Centre <a href="https://www.embl.org/about/info/support/embl-corporate-partnership-programme/" target="_blank" rel="noopener">Corporate Partnership Programme (CPP)</a>. If awarded a fee waiver, you will be receive a reimbursement for the registration costs, after you attend VIZBI &mdash; either on-site or online. The waiver does not cover the Masterclass.</p>

    <h4 id="travel"><a name="travel"></a>Travel grants</h4>
    <p>A limited number of travel grants are also available <a href="https://embo.org" target="_blank" rel="noopener">EMBO</a> and the EMBL <a href="https://www.embl.org/about/info/support/embl-corporate-partnership-programme/" target="_blank" rel="noopener">CPP</a>. The travel grant will cover travel cost (including airfare, train, bus, taxi, accommodation, visa, and/or registration fees) required to attend the VIZBI on-site event. The grant typically provides the following amounts:
      <ul>
        <li>Up to €1,000 for a participant working in Chile, India, Singapore or Taiwan.</li>
        <li>Up to €700 for a participant working in Croatia, Czech Republic, Estonia, Greece, Hungary, Italy, Lithuania, Luxembourg, Poland, Slovenia, and Turkey travelling to an EMBO Workshop.</li>
        <li>Up to €500 for a participant working in other countries.</li>
      </ul>
    </p>

    <p>However, the VIZBI organisers may reduce the grant cap to accommodate more participants. Recipients will be notified of their travel cap amount when they are informed of the outcome of their application. Original receipts must be provided with your signature for all costs incurred within two months of completion of travel. Scanned copies cannot be accepted.</p>

    <h4 id="childcare"><a name="childcare"></a>Childcare grants</h4>
    <p>For the VIZBI on-site workshop, there is the possibility to apply for a childcare grant to offset child care costs incurred by participants or speakers. Eligible costs include fees for a baby-sitter or child-care facility, travel costs for a caregiver, or travel costs for taking the child to the meeting etc. Please note that priority will be given to early stage researchers. A maximum amount of €500 can be awarded per participant.</p>

    <h4 id="application"><a name="application"></a>Applying for grants and fee waivers</h4>
    <p>To apply, you need to complete the following steps by <b>22 January 2023</b>:</p>
    <ol>
      <li>Register for VIZBI using the button above.</li>
      <li>Submit your application using the link sent in your registration confirmation email. You need to provide the following information:</li>
      <ul>
        <li>A title and 250 word abstract for the work you plan to present in your VIZBI poster.</li>
        <li>The name and email address of your lab head, as well as the institute and country you are based.</li>
        <li>A statement from your supervisor explaining support provided by the laboratory budget.</li>
        <li>A statement of support from your supervisor explaining their support for your attendance.</li>
        <li>Reasons why your lab cannot fund your attendance.</li>
        <li>An account of how attending this workshop will make a difference to your career.</li>
        <li>A description of how your work will contribute to the themes of this workshop.</li>
      </ul>
    </ol>

    <p>If you have any questions about fee waivers, travel grants or childcare grants, please contact the <router-link to="Contact">EMBL conference office</router-link>.</p>

    <p>If you are awarded a fellowship, you will be notified in late January. Please note that you must pay your registration fee by the payment deadline (<b>14 February 2023</b>). The EMBL Course &amp; Conference Office will request your bank details and will reimburse the registration fees after the event. Travel Grants can only be reimbursed after the event, and once the receipts have been submitted.</p>

    <h4 id="press"><a name="press"></a>Event reporters and journalists</h4>
    <p>For scientists who cannot attend due to financial constraints, EMBL is offering free places to <a href="https://www.embl.org/about/info/course-and-conference-office/information-for-participants/become-an-event-reporter/">event reporters</a> who will post to social media from the conference and write a report summarising the meeting. In addition, complimentary or reduced registration may be available to accredited journalists. Please <router-link to="Contact">contact us</router-link> for more information. Please note that we do not offer complimentary registrations for editors of scientific journals.</p>

    <h4 id="Income">Masterclass discounts</h4>
    <p>Participants residing in low income countries receive discounted registration fees for the Masterclass (but for VIZBI). The discounted rate is automatically shown in the registration fee table (above), based on your location. Following ISCB guidelines, these discounts are based on the <a href="https://datatopics.worldbank.org/world-development-indicators/the-world-by-income-and-region.html">World Bank</a> classification of countries by income.</p>

    <h4 id="further"><a name="further"></a>Further financial assistance</h4>
    <p>For further information about financial assistance, the EMBL conference office maintains a list of <a href="https://www.embl.org/about/info/course-and-conference-office/information-for-participants/external-funding-sources/" target="_blank" rel="noopener">external funding opportunities</a>. Please also refer to the EMBL conference office <a href="https://www.embl.org/about/info/course-and-conference-office/information-for-participants/frequently-asked-questions/">FAQ page</a>.</p>

    <!--<h4 style="margin-bottom: 0px;">Refund and cancellation policies</h4>
    <p>If you need to change or cancel your registration, send a written request to <Email to="vizbi-contact" />. Cancellation requests received prior to March 1, 2023, will receive a 50% refund. After this date, no refund of fees will be made.
    </p> -->
  </div>
</template>

<script scoped>
  import Button from '/src/components/Button';
  import feesThisYear from '@/assets/years/2023/Registration/fees.json';
  import Iscb from '@/components/Iscb.vue';
  import RegistrationFee from '@/components/RegistrationFee.vue';
  import stringify from "json-stringify-safe";
  export default ({
    name: 'Index',
    components: {Button, Iscb, RegistrationFee},
    data() {
      return {
        fees: feesThisYear,
        events: ['VIZBI (on-site)', 'VIZBI (online)', 'Masterclass (online-only)'],
        categories: ['Industry', 'Academic', 'Student' ],
        iscb_member: false,
        showDialog: false,
        links: {
          'VIZBI (on-site)': 'https://embl.ungerboeck.com/PROD/emc00/register.aspx?aat=osPUQeBQ9Rdd9BDfhfFTgX3WEu1cACCmejNHUiJbgXA%3d',
          'VIZBI (on-site)-ISCB-discount': 'https://embl.ungerboeck.com/PROD/emc00/register.aspx?OrgCode=10&EvtID=6489&AppCode=REG&CC=122112934150',
          'VIZBI (online)': 'https://embl.ungerboeck.com/PROD/emc00/register.aspx?OrgCode=10&EvtID=6500&AppCode=REG&CC=122101026516',
          'VIZBI (online)-ISCB-discount': 'https://embl.ungerboeck.com/PROD/emc00/register.aspx?OrgCode=10&EvtID=6500&AppCode=REG&CC=122121303651',
          'Masterclass (online-only)': 'https://www.iscb.org/cms_addon/registration_conference/vizbi-Masterclass-2023'
        }
      };
    },
    methods: {
      log(message) { // eslint-disable-line
        if (process.env.NODE_ENV === 'development') {
          console.log(message); // uncomment to show logs from this component
        }
      },
      main(title) {
        let [maintitle, subtitle] = title.split(' ');
        return maintitle;
      },
      subtitle(title) {
        let [maintitle, subtitle] = title.split(' ');
        return subtitle;
      },
      tweak(title) {
        let [maintitle, subtitle] = title.split(' ');
        maintitle = maintitle.replace(/VIZBI/, "VIZBI<span class='wideContent'> workshop</span>");
        return `${maintitle}<br><span style="font-weight: 300;"> ${subtitle.toLowerCase()}</font>`;
      },
      tweak2(title) {
        title = title.replaceAll(/\(|\)/g,''); // remove brakets
        title = title.replace(/online-only/,''); // remove
        return title;
      },
      tweak3(title) {
        // translate events into organizatons
        if (title.match(/VIZBI/)) {
          return '<a href="https://www.embl.org/about/info/course-and-conference-office/">EMBL</a>'
        } else {
          return '<a href="http://iscb.org/">ISCB</a>'
        }
      },
      goToForm(site) {
        if (this.iscb_member && site !== 'Masterclass (online-only)') {
          site += '-ISCB-discount';
        }
        let url = this.links[site];
        this.log(`url = ${url}`);
        window.open(url, '_blank', 'noreferrer');
      },
      defocus(event) {
        this.log(`defocus(${event})`);
        this.$refs[event][0].focus()
      }
    }
  });
</script>

<style scoped>
  .submit:active {
    position: relative;
    top: 1px;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  table {
    border-collapse: collapse;
    margin-top: 1em;
    margin-bottom: 1.5em;
    width: 100%;
    text-align: left;
  }
  th {
    padding-right: 12px;
    color: #434343;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: -0.05em;
    border-bottom: 12px solid #FFF;
    vertical-align: top;
    border-bottom: 8px solid #FFF;
  }
  tr {
    vertical-align: top;
    border-bottom: 2px solid #FFF; /* set vertical space between rows */
  }
  td,
  th {
    padding: 3px;
    hyphens: auto; /* great for narrow screens! */
    vertical-align: top;
    line-height: 1.3em;
  }
  td {
    font-size: 16px;
  }
  td + td, th + th {
    /* set horizontal space between columns */
    border-left: 42px solid #FFF;
  }
  .very-narrow-screen td + td, .narrow-screen td + td, .very-narrow-screen th + th, .narrow-screen th + th {
    /* set horizontal space between columns for narrow screens */
    border-left: 18px solid #FFF;
  }
  td p, td span, td small {
    margin: 0px;
    padding: 0px;
    line-height: 1.2;
  }
  .event {
    display: flex; /* outer container for event arrow / title / description */
  }
  span.arrows {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 300;
    margin-right: 6px;
  }
  span.eventTitle {
    flex-direction: column;
    font-size: 14px;
    font-weight: 500;
  }
  table button {
    width: 100%;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
  }
  button {
    margin-top: 8px; /* 18 */
    margin-bottom: 8px;  /* 36px */
    font-size: 15px;
  }
  button[disabled] {
    /* pointer-events: none; disabled cause it hides title */
    cursor: default;
    color: #929292;
    background-color: #F5F3F7;
    border: 1px solid rgba(60, 60, 60, 0.2);
  }
  .right-margin {
    margin-right: 12px;
  }
  li:not(:last-child) {
     margin-bottom: 3px;
  }
  .very-narrow-screen .wideContent, .narrow-screen .wideContent {
    display: none;
  }
  .medium-screen .narrowContent, .wide-screen .narrowContent {
    display: none;
  }

</style>
