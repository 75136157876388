<template>
  <div>
    <a name="top" />
    <table style="text-align: left; width: 100%;">
      <colgroup>
        <col span="1" style="width: 36%;">
        <col span="1" style="width: 64%;">
      </colgroup>
      <tr>
        <th>VIZBI (online):</th>
        <th />
      </tr>
      <tr>
        <td>Industry:</td>
        <td>$400</td>
      </tr>
      <tr>
        <td>Academic:</td>
        <td>$350</td>
      </tr>
      <tr>
        <td>Student:</td>
        <td>$275</td>
      </tr>
    </table>
    <table style="text-align: left">
      <colgroup>
        <col span="1" style="width: 36%;">
        <col span="1" style="width: 64%;">
      </colgroup>
      <tr>
        <th>DataVis Masterclass (online):</th>
        <th />
      </tr>
      <tr>
        <td>Industry:</td>
        <td>$230</td>
      </tr>
      <tr>
        <td>Academic:</td>
        <td>$190</td>
      </tr>
      <tr>
        <td>Student:</td>
        <td>$150</td>
      </tr>
    </table>

    <p>Members of the <a href="http://iscb.org/"> International Society for Computational Biology</a> (ISCB) members receive a 15% registration discount for all above events. We encourage participants to consider joining the ISCB to take advantage of
      these and <a href="http://www.iscb.org/iscb-membership">other benefits</a>.</p>

    <p>
      <Email to="'vizbi-workshop" show="Contact us" /> if you are interested to be part of the Workshop on <a href="/2022/Workshop">Understanding COVID Data</a>.
    </p>

    <p><strong>Update Jan 15, 2022:</strong> Due to ongoing uncertainties around COVID, VIZBI 2022 will now take place as a fully virtual event.</p>

    <span>
      <input id="submit" type="button" value="Click to open the registration form" onclick="window.location.href='Registration/Form'">
    </span>
    <h4 style="margin-bottom: 0px;">Refund and cancellation policies</h4>
    <p>If you need to change or cancel your registration, send a written request to <Email to="vizbi-registration" />. Cancellation requests received prior to March 1, 2022, will receive a 50% refund. After this date, no refund of fees will be made.
    </p>
  </div>
</template>

<script scoped>
  import Email from '@/components/Email.vue';
  export default ({
    name: 'Index',
    components: {
      Email
    }
  });
</script>

<style scoped>
#submit:hover {
    background: linear-gradient(to bottom, #ef027d 5%, #ff5bb0 100%);
    background-color: #ef027d;
  }

  #submit:active {
    position: relative;
    top: 1px;
  }

  div#page input#gotoRegisterationPage {
    /* hides the 'gotoRegisterationPage' button on this page */
    display: none !important;
  }

  th {
    padding-right: 12px;
    color: #999;
    font-size: 80%;
    font-weight: 900;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }

  table {
    border-collapse: collapse;
    margin-bottom: 1em;
    width: 100%;
  }

  tr {
    vertical-align: top;
  }

  td,
  th {
    padding: 6px;
  }

  tr:nth-child(even) {
    background: #EEE;
  }

  input {
    background-color: #F4B;
    box-shadow: inset 0px 1px 0px 0px #fbafe3;
    background: linear-gradient(to bottom, #ff5bb0 5%, #F4B 100%);
    border-radius: 6px;
    border: 1px solid #ee1eb5;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 15px;
    font-weight: bold;
    padding: 6px 24px;
    margin-top: 12px;
    margin-bottom: 18px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #c70067;
  }
</style>
