<!-- Copyright: Sean I. O'Donoghue -->

<template>
  <meta http-equiv="Content-Security-Policy" content="default-src *; style-src 'self' 'unsafe-inline'; script-src 'self' 'unsafe-inline' 'unsafe-eval'">
  <transition mode="out-in" appear>
    <section v-if="step === 1">
      <h5 v-if="showcase">To view this video, register for {{showcase}}:</h5>
      <p v-if="localStorage && localStorage.email">
        {{ localStorage.email }} is currently not registered for this event.
      </p>
      <div>
        <button>Register</button>
        <button v-if="localStorage && localStorage.email" @click="step = 2">
          Use a different address
        </button>
        <button v-else @click="step = 2">I'm already registered</button>
      </div>
    </section>
    <section v-else-if="step === 2">
      <div>
        <h5>To login, please enter your email address:</h5>
      </div>
      <div>
        <input
          v-model="address"
          v-autowidth="{maxWidth: '960px', minWidth: '300px', comfortZone: 0}"
          type="email"
          placeholder="Type your email address"
          @keypress.enter="email_is_valid(address) && verify_email(address)"
        >
      </div>
      <div>
        <button type="button" @click="step = 1">Go back</button>
        <button
          type="button"
          @click="verify_email"
          :disabled="! email_is_valid(address)"
        >
          Log in
        </button>
      </div>
    </section>
    <section v-else-if="step === 3 && ! token_in_url">
      <h5 v-if="token_in_url">
        To confirm your login on this browser, click below:
      </h5>
      <h5 v-else>
        Check your email for the code to authenticate this browser:
      </h5>
      <p>In some cases, you may need to check your junk folder.</p>
      <div>
        <input
          v-if="! token_in_url"
          v-model="token"
          v-autowidth="{maxWidth: '960px', minWidth: '450px', comfortZone: 0}"
          type="text"
          placeholder="Paste authorisation code here"
        >
      </div>
      <div>
        <button type="button" @click="step = 2">Go back</button>
        <button type="button" @click="authenticate(token)">Play video</button>
      </div>
    </section>
  </transition>
  <!-- <p v-if="message">message = {{ message }}</p> -->
</template>

<script>
// https://bestofvue.com/repo/syropian-vue-input-autowidth-vuejs-admin-template
  import VueInputAutowidth from 'vue-input-autowidth';
  import stringify from 'json-stringify-safe';
  export default {
    name: "Authorize",
    // Settings below fix some VueInputAutowidth errors with Vue3
    // https://bestofvue.com/repo/syropian-vue-input-autowidth-vuejs-admin-template
    directives: {
      autowidth: {
        beforeMount: VueInputAutowidth.bind,
        mounted: VueInputAutowidth.inserted,
        updated: VueInputAutowidth.componentUpdated,
        unmounted: VueInputAutowidth.unbind,
      },
    },
    props: {
      showcase: {type: String, default: ''}
    },
    data() {
      return {
        step: 1, // initially, start at step 1
        address: '',
        token: '',
        token_in_url: false, // true when user clicks on email link
        message: ''
      };
    },
    async mounted() {
      this.log(`Login mounted`);
      this.log(`URL path = ${this.$route.path}`);
      await this.login_status();
      this.read_token_from_URL();
    },
    methods: {
      log(message) { // eslint-disable-line
        console.log(message); // uncomment to show logs from this component
        if (process.env.NODE_ENV === 'development') {
          //console.log(message); // uncomment to show logs from this component
        }
      },
      async login_status() {
        if (!localStorage.loginToken) {
          return console.warn(`Not logged in`);
        }
        try {
          let response = await this.login_status_call();
          //response = await response.json();
          console.log(`response = ${stringify(response)}`);
          if (response && response.status === 'success') {
            this.log(`User is logged-in`);
            this.registered_events = response.events;
            return;
          } else {
            console.warn(`Not logged in: access to some videos is restricted`);
          }
        } catch (error) {
          console.error(`Error checking user login state: ${error}`);
        }
      },
      async login_status_call() {
        try {
          let response = await this.fetchWithTimeout(
            `${process.env.VUE_APP_API}/user/login_status`, {
              method: 'GET',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.loginToken}`
              },
            }
          );
          response = await response.json();
          return response;
        } catch (error) {
          console.error(`Error checking user login state: ${error}`);
        }
      },
      email_is_valid (email) {
        // https://v2.vuejs.org/v2/cookbook/form-validation.html
        let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // this.log(`email = ${email}`);
        return regex.test(email);
      },
      async verify_email() {
        this.log(`verify_email() called.`);
        let response = await this.verify_email_call();
        response = await response.json();
        if (response.error) {
          this.message = response.error;
        } else {
          this.log(`All good - confirmation email should be sent.`);
          this.message = `Now check your email to authenticate access from this browser.`;
          this.step = 3;
        }
        return this.message;
      },
      async fetchWithTimeout(resource, options = {}) {
        // https://dmitripavlutin.com/timeout-fetch-request/
        const { timeout = 8000 } = options;
        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), timeout);
        const response = await fetch(resource, {
          ...options,
          signal: controller.signal
        });
        clearTimeout(id);
        return response;
      },
      async verify_email_call() {
        try {
          this.log(`verify_email_call() called.`);
          return await this.fetchWithTimeout(
            `${process.env.VUE_APP_API}/user/verify_email`, {
              method: 'POST',
              headers: {'Content-Type': 'application/json'},
              body: stringify({
                'email': this.address,
                'path': this.$route.path
              })
            });
        } catch (error) {
          this.log(`Call unsuccessful.`);
          this.message = `Problem calling verify_email: ${error}`;
        }
      },
      async authenticate(token) {
        if (!token) {
          console.error(`authenticate() called with no token`);
          return;
        }
        this.log(`authenticate() called`);
        let response = await this.authenticate_call(token);
        response = await response.json();
        if (!response) {
          this.message = 'No response from server - please try again later.';
        } else if (response.status && response.status === 'success') {
          this.log(`Login is now authenticated for this browser.`);
          window.localStorage.loginToken = response.loginToken;
          window.localStorage.events = response.events;
          window.localStorage.email = this.address;
          this.step = ''; // hides login steps
          this.$emit('success'); // notify 'Video' component to re-run lookup
          return;
        } else if (response.error === 'expired token') {
          this.message = `Authentication code has expired - please enter your email again`;
        } else {
          this.message = `Unknown error with authentication code`;
          // must be an error, so reset and go back a step
          this.token_in_url = false; // in case it was true
          this.step = 2;
        }
      },
      async authenticate_call(token) {
        this.log(`authenticate_call() called`);
        this.log(`token = ${token}`);
        try {
          return await this.fetchWithTimeout(
            `${process.env.VUE_APP_API}/user/authenticate`, {
              method: 'POST',
              headers: {
                //'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: stringify({
                'token': token
              })
            }
          );
        } catch (error) {
          this.message = `Problem verifying authentication code: ${error}`;
        }
      },
      read_token_from_URL() {
        if (!window.location.hash) {
          return this.log(`URL fragment is empty.`);
        }
        const token = window.location.hash.replace(/^#/, ''); // read token
        window.location.hash = ""; // remove token from frament
        if (token) {
          this.log(`In URL fragment: token = ${token}`);
          this.step = 3;
          this.token_in_url = true;
          this.authenticate(token);
        }
      }
    },
  };
</script>

<style scoped>
  /* https://stackoverflow.com/questions/59632929 */
  .v-enter-from {
    opacity: 0;
  }

  .first-enter-from {
    opacity: 0;
  }

  .v-enter-to {
    opacity: 1;
    /* transform: scale(1); */
  }

  .v-enter-active {
    transition: all 0.5s ease;
  }

  /* .v-move {transition: all 5s ease;} */

  .v-leave-from {
    opacity: 1;
  }

  .v-leave-to {
    opacity: 0;
  }

  .v-leave-active {
    transition: all 0.5s ease;
  }

  section {
    display: grid; /* https://stackoverflow.com/questions/65535788 */
    background-color: #F8F8F8;
    border: 1px solid #BDBDBD;
    margin-top: 25px;
    padding: 25px;
    row-gap: 15px;  /* horizontal space between buttons on same row */
    height: 15vh;
    margin-bottom: 82px;
    transition: max-height 300ms;
  }

  section h5, section p, section div  {
    display: flex; /* https://www.w3docs.com/tools/code-editor/6105 */
    align-items: center;
    justify-content: center;
    column-gap: 15px;
    margin: 0px;
    padding: 0px;
  }

  section p  {
    font-style: italic;
  }

  button,
  input {
    margin: 0px; /* 20px */
    text-align: center;
    /* float: right; */
  }

  .backButton {
    left: 0%;
    top: 0%;
    transform: translate(-20%, -20%);
    background: linear-gradient(to bottom, #BDBDBD 5%, #D5D5D5 100%);
    border: 1px solid #BDBDBD;
    box-shadow: inset 0px 1px 0px 0px #BDBDBD;
    text-shadow: 0px 1px 0px #BDBDBD;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    color: #ffffff;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
  }
</style>
