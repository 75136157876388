<!-- Copyright: Sean I. O'Donoghue -->

<template>
  <div id="header">
    <div class="left_column">
      <mainSponsors :selected-year="selectedYear" />
      <ButtonMail />
      <Logo />
    </div>
    <div class="right_column">
      <SearchBox />
      <NavigationPrimary />
    </div>
  </div>
</template>

<script>
import mainSponsors from './MainSponsors.vue';
import Logo from './Logo.vue';
import ButtonMail from './ButtonMail.vue';
import SearchBox from './SearchBox.vue';
import NavigationPrimary from './NavigationPrimary.vue';
export default {
  name: 'Header',
  components: {
    mainSponsors,
    ButtonMail,
    Logo,
    SearchBox,
    NavigationPrimary
  },
  props: {
    // https://stackoverflow.com/questions/51671818
    selectedYear: {
      default: 0,
      type: [Number, String]
    }
  },
};
</script>

<style scoped>                   /* scoped = CSS limit to this component only */
  #header {        /* https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */
    /*display: inline-flex; */
    display: flex;
    flex-direction: row;
    margin-top: 6px;
  }
  #header .left_column {
    width: 85%;
    /* flex-grow: 65;            /* Amount of positive free space item gets */
    /* flex-shrink: 0;        /* Amount of negative space can be removed item */
    /* flex: 0.8 0; */
    /*flex-basis: 790px;   /* Size of item before growing and shrinking happens */
    /* display: table-cell; */
    /* border-style: dotted; */
    /* padding: 0px 8px 0px 8px; */
  }
  #header .right_column {
    width: 15%;
    /* display:table-cell !important; */
    /* flex-grow: 35;            /* Amount of positive free space item gets */
    /* flex-shrink: 0;      /* Amount of negative space can be removed item */
    /*flex: 0.1 0; */
    /*flex-basis: 70px;  /* Size of item before growing and shrinking happens */
    text-align: right;
  }
  .narrow-screen #header .left_column, .very-narrow-screen #header .left_column {
    width: 94%;
  }
  .narrow-screen #header .right_column, .very-narrow-screen #header .right_column {
    width: 6%;
  }
  .narrow-screen #header, .very-narrow-screen #header {
    min-height: 40px;
    align-items: center;
    margin: 0px;
    /* https://www.jakpsatweb.cz/css/css-vertical-center-solution.html */
  }
  #header .left_column, #header .right_column {
    padding: 0px 8px 0px 8px;
  }
</style>
