import {
  createRouter,
  createWebHistory
  //createWebHashHistory
} from 'vue-router';
import Home from '../views/Home.vue';
import Admin from '../views/Admin.vue';
import Blog from '../views/Blog.vue';
import Posters from '../views/Posters.vue';
import Mail from '../views/Mail.vue';
import Videos from '../views/Videos.vue';

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    alias: '/Home' // https://stackoverflow.com/questions/47160546
  },
  {
    path: '/Admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/Blog',
    name: 'Blog',
    beforeEnter() {window.location.href = "https://www.vizbi.org/blog";}
    //component: Blog
  },
  {
    path: '/Posters/:specification*',
    name: 'Posters',
    beforeEnter(to, from, next) {
      window.location.href = `https://www.vizbi.org${to.path}`;
    }
  },
  {
    path: '/Lightning/:specification*',
    name: 'Lightning',
    beforeEnter(to, from, next) {
      window.location.href = `https://www.vizbi.org${to.path}`;
    }
  },
  {
    path: '/Plus:specification*',
    name: 'Plus',
    beforeEnter(to, from, next) {
      window.location.href = `https://www.vizbi.org${to.path}`;
    }
  },
  {
    path: '/Mail',
    name: 'Mail',
    component: Mail
  },
  {
    path: '/Videos/:vimeoHash(\\w+)?',
    name: 'Videos',
    component: Videos
  },
  {
    path: '/:year(201\\d)',
    name: 'YearRedirect',
    beforeEnter(to, from, next) {
      window.location.href = `https://www.vizbi.org${to.path}`;
    }
  },
  {
    path: '/:year(2020)',
    name: 'YearRedirect1',
    beforeEnter(to, from, next) {
      window.location.href = `https://www.vizbi.org${to.path}`;
    }
  },
  {
    path: '/:year(2021)',
    name: 'YearRedirect2',
    beforeEnter(to, from, next) {
      window.location.href = `https://www.vizbi.org${to.path}`;
    }
  },
  {
    path: '/:year(202\\d)', // year(20\\d\\d)
    name: 'Year',
    component: () => import('../views/Year/Index.vue'),
    children: [
      {path: '', name: 'About', component: () => import('../views/Year/About.vue')},
      {path: 'People', name: 'People', component: () => import('../views/Year/People.vue')},
      {path: 'Program', name: 'Program', component: () => import('../views/Year/Program.vue')},
      {path: 'Contact', name: 'Contact', component: () => import('../views/Year/Contact.vue')},
      {path: 'VR', name: 'VR', component: () => import('../views/Year/VR.vue')},
      {path: 'Masterclass', name: 'Masterclass', component: () => import('../views/Year/Masterclass.vue')},
      {path: 'Showcase', name: 'Showcase', component: () => import('../views/Year/Showcase.vue')},
      {path: 'Platform', name: 'Platform', component: () => import('../views/Year/Platform.vue')},
      {path: 'Workshop', name: 'Workshop', component: () => import('../views/Year/Workshop.vue')},
      {path: 'Sponsors', name: 'Sponsors', component: () => import('../views/Year/Sponsors.vue')},
      // name='Poster' not 'Posters': short and distinct from vizbi.org/Posters
      {path: 'Posters', name: 'Poster', component: () => import('../views/Year/Posters.vue')},
      {path: 'Registration', name: 'Registration', component: () => import('../views/Year/Registration.vue')},
      {path: 'Registration/Form', name: 'Form', component: () => import('../views/Year/Form.vue')},
      {path: 'Venue', name: 'Venue', component: () => import('../views/Year/Venue.vue')},
    ]
  },
  {
    path: '/Test',
    name: 'Test',
    component: () => import('../components/Test.vue')
  },
   // redirect everything else to the current year
  {path: '/:pathMatch(.*)*', redirect: '/2023'}
];
//component: () => import(/* webpackChunkName: "about" */ 'About.vue')
// scroll solution below from https://stackoverflow.com/questions/54535838
function wait(duration) {
  return new Promise((resolve) => setTimeout(resolve, duration));
}
const router = createRouter({
  history: createWebHistory(), // createWebHashHistory
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      // Scroll to named anchor once page is loaded
        const hash = to.hash;
        let handleScrollToAnchor = (hash) => {
          if (document.querySelector(hash)) {
            // excludes cases where the hash is used to filter, not navigation
            document
                  .querySelector(hash)
                  .scrollIntoView({ behavior: "smooth", block: "start"});
          }
        }
        let timer = setInterval(()=> {
            if (document.readyState === 'complete') {
                clearInterval(timer)
                handleScrollToAnchor(hash);
              }
            }, 300);
        // may not need this, but we do it anyway
        return {
          selector: decodeURI(to.hash),
          offset: { x: 0, y: 80 },
        };
    }
  }
});
export default router;
