<template>
  <p>The VIZBI Masterclass is an online-only training event that will take place just prior to the <router-link to="/2023">VIZBI 2023</router-link> workshop. This satellite meeting spans two half-days, and aims to provide a broad overview covering state-of-the-art methods in data visualization, taught by leading experts and practioners. The event is aimed at anyone working with large, complex datasets. This includes scientists, programmers, communicators, and designers.</p>
  <HeroImage file='2023/Masterclass/img/banner/low_res.jpg' credit='O&#39;Donoghue, Stolte, Kozlíková, Viola' />
  <p>The course covers advanced data visualization methods that can be used to create insightful visual representations of complex
    data, and to create compelling visualizations for communication. These methods rely on principles of human visual perception,
    as well a knowledge of modern techniques and tools. Unfortunately, there are currently few opportunities to receive
    training in these newly emerging methods.</p>
  <p>To address this, we designed the Data Visualization Masterclass as an eight-hour event to provide participants with an up-to-date
    overview of current DataVis methods, as well as the opportunity to connect with &#8212; and learn directly from &#8212;
    leading DataVis practioners.</p>
  <p>For 2023, the Masterclass runs over two half-days (Monday and Tuesday, March 27-28), from <a href="https://www.timeanddate.com/worldclock/meetingtime.html?iso=20230327&p1=37&p2=136&p3=179&p4=224" target="_blank" rel="noopener">14:00-18:00 Central European time</a>. If you have questions about the Masterclass, please write to <Email to="masterclass"/>.</p>
</template>

<script>
  import Email from '@/components/Email.vue';
  import HeroImage from '@/components/HeroImage.vue';
  export default {
    components: { Email, HeroImage }
  };
</script>

<style scoped>
  table td {
    vertical-align: top;
    margin-top: 29px;
    margin-bottom: 29px;
  }
  img {
    width: 100%;
  }
</style>
