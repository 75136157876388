<!-- https://www.techighness.com/post/get-user-country-and-region-on-browser-with-javascript-only/ -->
<!-- Copyright: Sean I. O'Donoghue -->

<template>
  <span>{{ fee() }}</span>
</template>
<script>
  // https://www.techighness.com/post/get-user-country-and-region-on-browser-with-javascript-only/
  import countries from '/src/assets/general/regions-to-countries.json';
  // https://datatopics.worldbank.org/world-development-indicators/the-world-by-income-and-region.html
  import income from '/src/assets/general/countries-to-income.json';
  export default ({
    name: 'RegistrationFee',
    props: ['fees', 'event', 'category', 'iscb_member'],
    computed: {
      country() {
        if (Intl) {
          let region = Intl.DateTimeFormat().resolvedOptions().timeZone;
          return countries[region];
        } else {
          console.log('Intl not defined');
          return '';
        }
      },
      income() {
        if (this.country) {
          return income[this.country];
        } else {
          console.log('this.country not defined');
          return '';
        }
      },
    },
    methods: {
      log(message) {                                 // eslint-disable-line
        if (process.env.NODE_ENV === 'development') {
          //console.log(message); // uncomment to show logs from this component
        }
      },
      fee() {
        this.log(`income = ${this.income}`);
        let discount = 0;
        let iscb_discount = 0.15;
        if (this.income && this.event && this.event.includes('Masterclass')) {
          // apply discounts for low-income countries
          //fees[this.event][this.category] =
          if (this.income === 'Low income') {
            discount = 0.90 - iscb_discount; /* 90% for ISCB members */
          } else if (this.income === 'Lower middle income') {
            discount = 0.60 - iscb_discount; /* 60% for ISCB members */
          } else if (this.income === 'Upper middle income') {
            discount = 0.30 - iscb_discount; /* 30% for ISCB members */
          }
        }
        if (this.iscb_member) {discount += iscb_discount}
        this.log(`discount = ${discount}`);
        this.log(`this.event = ${this.event}`);
        this.log(`this.category = ${this.category}`);
        //return '€' + this.fees[this.event][this.category] * (1 - discount)
        //return fees[this.event]["Student"];
        let fee = (1 - discount) * this.fees[this.event][this.category]
        return '€' + Math.round(fee);
      }
    }
  });
</script>
