<!-- Copyright: Sean I. O'Donoghue -->

<template>

  <DialogModal @hideDialog="hitItAndQuit">
    <div id="container">
      <h4>Apply ISCB Discount</h4>
      <p>To receive a 15% discount for these events, enter the email address associated with your ISCB membership:</p>
      <input type="text" id="email" name="email" placeholder="Enter your ISCB email" v-model="email" :disabled="iscb_member" ref="email" @keyup.enter="lookupEmail">
      <Button :outstanding="true" @click="lookupEmail" :disabled="!validEmailSyntax">Verify email</Button>
      <div v-if="this.validation_outcome" id="message">{{ this.validation_outcome }}</div>
    </div>
  </DialogModal>
</template>

<script>
  import Button from '/src/components/Button';
  import DialogModal from '@/components/DialogModal.vue';
  import stringify from "json-stringify-safe";
  export default {
    name: 'Iscb',
    // https://stackoverflow.com/questions/64220737
    emits: ['emailValid', 'hideDialog'],
    components: {Button, DialogModal},
    data() {
      return {
        email: '',
        iscb_member: false,
        validation_outcome: '',
      }
    },
    computed: {
      validEmailSyntax() {
        this.log(`Called validEmailSyntax()`);
        // https://stackoverflow.com/questions/46155
        let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return String(this.email).toLowerCase().match(regex);
      }
    },
    mounted() {
      document.getElementById("email")?.focus(); // set initial focus to input
    },
    methods: {
      log(message) { // eslint-disable-line
        if (process.env.NODE_ENV === 'development') {
          console.log(message); // uncomment to show logs from this component
        }
      },
      hitItAndQuit() {
        this.log(`Iscb/hitItAndQuit()`);
        this.$emit('hideDialog');
      },
      async lookupEmail() {
        this.log(`email = ${this.email}`);
        if (!this.validEmailSyntax) {
          this.log('email syntax not yet valid');
          return; // early return
        }
        const url = `${process.env.VUE_APP_API}/iscb/member`;
        try {
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: stringify({
              'email': this.email
            })
          });
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const outcome = await response.json();
          if (outcome) {
            this.iscb_member = outcome.iscb_member;
            this.log(stringify(outcome));
            if (this.iscb_member) {
              this.$emit('emailValid'); // , 'someValue')
              this.validation_outcome = '';
              //'Use the buttons below to register with ISCB discount.';
            } else {
              this.validation_outcome = 'This email is not linked to an ISCB membership.';
              // move focus back to input field
              // https://michaelnthiessen.com/set-focus-on-input-vue
              this.$refs["email"].focus();
            }
          } else {
            this.validation_outcome = 'Cannot access ISCB database; Please try later.';
          }
          return;
        } catch (error) {
          this.log(`Request failed: ${JSON.stringify(error)}`);
        }
        // Here are .htaccess settings needed to get this working:
        // Header add Access-Control-Allow-Credentials true
        // Header add Access-Control-Allow-Origin "*"
        // Header add Access-Control-Allow-Headers "origin, x-requested-with, content-type, Access-Control-Allow-Origin"
        // Header add Access-Control-Allow-Methods: "GET,POST,OPTIONS,DELETE,PUT"
        // Vary: Origin
        //
      }
    }
  }
</script>

<style scoped>
  div#container {
    margin: auto;
    /* width: 85%; */
    font-size: 16px;
  }
  h4 {
    margin-top: 0px;
  }
  p {
    font-size: 14px;
    text-align: left;
    width: 100%;
  }
  input {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 9px;
    padding-bottom: 9px;
    text-align: center;
    width: 100%;
  }
  button {
    margin-top: 1px; /* 18 */
    margin-bottom: 8px;  /* 36px */
    width: 100%;
    text-align: center;
  }
  #message {
    color: orange;
    margin-bottom: 0.7em;
  }
</style>
