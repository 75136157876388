<!-- Copyright: Sean I. O'Donoghue -->

<template>
  <h1 id="scalable-font" v-resize-text="{ratio: 1.2, minFontSize: '28px', maxFontSize: '50px', delay: 200}">
    VISUALIZING BIOLOGICAL DATA
    <span id="logo">
      <router-link to="/">VIZBI</router-link>
    </span>
  </h1>
  <h1 id="fixed-font">
    VISUALIZING BIOLOGICAL DATA
    <span id="logo">
      <router-link to="/">VIZBI</router-link>
    </span>
  </h1>
</template>

<script>
  import VueResizeText from 'vue3-resize-text';
  export default {
    name: 'Logo',
    directives: {
      ResizeText: VueResizeText.ResizeText
    }
  }
</script>

<style scoped>
  img {
    /* May need some adjustments depending on aspect ratio etc */
    /* https://stackoverflow.com/questions/3029422 */
    vertical-align: middle;
    margin-top: 5px;
    margin-left: 0px;
    padding: 0px;
    max-width: 15%;
  }
  /* scoped = CSS limit to this component only */
  h1 {
    font-weight: 400;
    font-size: 3.12em; /* was: 2.42em 3.12em;*/
    line-height: 0.9em;
    margin-top: 0px;
    margin-bottom: 14px;
  }
  .narrow-screen h1#scalable-font, .very-narrow-screen h1#scalable-font {
    /* turn off scaleable-font version of title */
    display: none;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .narrow-screen h1#fixed-font, .very-narrow-screen h1#fixed-font {
    /* hide burger bars on wide screen devices */
    display: inline;
    font-size: 3.9vw; /* 0.9em */
    margin-left: 7px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    vertical-align: 5px;
    padding: 0px;
  }
  .medium-screen h1#fixed-font, .wide-screen h1#fixed-font {
    /* turn off fixed-font version of title */
    display: none;
  }
  a {
    text-decoration: none;
  }
  .america a {
    color: var(--text-color-america);
  }
  .europe a {
    color: var(--text-color-europe);
  }
  .australia a {
    color: var(--text-color-australia);
  }
  span#logo {
    font-weight: 600;
    text-shadow: 0px 0px 8px #333;
  }
  /* color while hovering or selected */
  .america a:hover, .america a.selected  {
    color: var(--text-color-america);
  }
  .europe a:hover, .europe a.selected {
    color: var(--text-color-europe);
  }
  .australia a:hover, .australia a.selected {
    color: var(--text-color-australia);
  }
</style>
