<template>
  <div>
    <!--
    <h4>Primary sponsor</h4>
    <p>
      <a href="https://embl.org" target="_blank" rel="nofollow">
        <img src="./img/embo-primary-black.svg" alt="EMBO">
      </a>
    </p>-->

    <!--<h4>Co-sponsors</h4>-->
    <p><a href="https://www.biologists.com/" target="_blank" rel="nofollow">
      <img src="./img/CoB.svg" alt="CoB" title="The Company of Biologists">
    </a></p>

    <h4>Affiliations</h4>
      <p>
      <a href="https://www.embl.de/" target="_blank" rel="nofollow">
       <img src="./img/embl.svg" alt="EMBL" title="EMBL">
     </a>
     &nbsp;
     <a href="http://www.iscb.org/" target="_blank" rel="nofollow">
       <img src="./img/ISCB.jpeg" alt="ISCB" title="ISCB">
     </a>
     &nbsp;
     <a href="https://www.eg.org/" target="_blank" rel="nofollow">
       <img src="./img/EG.svg.png" alt="Eurographics" title="Eurographics">
     </a>
    </p>
  </div>
</template>

<style scoped>
  img {
    /* May need some adjustments depending on aspect ratio etc */
    /* https://stackoverflow.com/questions/3029422 */
    max-height: 60px;
    vertical-align: middle;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  a + a {
    /* horizontal space between images */
    margin-left: 16px;
  }
</style>
