<!-- Copyright: Sean I. O'Donoghue -->

<template>
  <div id="burger" :class="{ 'active' : isBurgerActive }" @click.prevent="toggle">
    <slot>
      <button type="button" class="burger-button" title="Menu">
        <!-- <span class="hidden">Toggle menu</span> -->
        <span class="burger-text">Archive</span>
        <div class="burger-bar"></div>
        <div class="burger-bar"></div>
        <div class="burger-bar"></div>
      </button>
    </slot>
  </div>
</template>
<script>
import store from '@/store';
export default {
  // data: () => ({
  //     isBurgerActive: false
  // }),
  computed: {
    isBurgerActive() {
      return this.$store.state.isNavOpen;
    }
  },
  methods: {
    toggle() {
      this.$store.commit('toggleNav');
    }
  }
};
</script>
<style scoped>
div#burger {
  padding: 0px;
}
button.burger-button {
  /* display: inline; */
  /* padding: 0px;  */
  /* background-color: transparent; */
  /* border: 0; */
  /* box-shadow: none; */
  cursor: pointer;
  opacity: 0.45;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 0px;
  margin-right: 0px;
}
/* remove blue outline */
/* button:focus {outline: 0;} */

.narrow-screen button.burger-button, .very-narrow-screen button.burger-button {
  position: relative;
  height: 22px;
  width: 24px;
  z-index: 999;
  border: 0;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding: 4px;
  pointer-events: all;
  background-color: #7F7F7F;
}
.very-narrow-screen button.burger-button {
  width: 6.5vw;
}

/* Mobile first - very-narrow-screen */
.very-narrow-screen .burger-text {
  font-size: 8vw; /* was: 0.8rem 18px */
}
/* narrow-screen */
.narrow-screen .burger-text {
  font-size: 14px; /* was: 0.8rem 18px */
}
/* medium-screen */
.medium-screen .burger-text {
  font-size: 15px; /* was: 0.8rem 18px */
}
/* wide-screen */
.wide-screen .burger-text {
  font-size: 16px; /* was: 0.8rem 18px */
}
/* color while hovering or selected */
.america .burger-button:hover, .america .burger-button.selected  {
  color: var(--text-color-america);
}
.europe .burger-button:hover, .europe .burger-button.selected {
  color: var(--text-color-europe);
}
.australia .burger-button:hover, .australia .burger-button.selected {
  color: var(--text-color-australia);
}
.narrow-screen .burger-text, .very-narrow-screen .burger-text {
  /* hide text on narrow screen devices */
  display: none;
}
.medium-screen .burger-bar, .wide-screen .burger-bar {
  /* hide burger bars on wide screen devices */
  display: none;
}
.narrow-screen .burger-bar {
  width: 15px;
  height: 3px;
  background-color: #fff;
  margin-bottom: 3px;
}
.very-narrow-screen .burger-bar {
  width: 4.5vw;
  height: 3px;
  background-color: #fff;
  margin-bottom: 3px;
}
#burger.active .burger-bar {
  background-color: #fff;
  color: #fff;
}

</style>
