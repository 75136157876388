<!-- Copyright: Sean I. O'Donoghue -->

<template>
  <div id="main_content">
    <div class="left_column">
      <!-- Component sent from parent will appear here -->
      <slot />
      <!-- https://stackoverflow.com/questions/42992579 -->
    </div>
    <div class="right_column">
      <div
      v-if="this.$route.params.year && this.$route.params.year === '2023'">
        <img id="sponsor"
          src="@/assets/years/2023/Sponsors/img/embo-primary-black.svg"
          alt="EMBO" title="EMBO">
      </div>
      <TwitterFeed :key="rerenderTweets" />
    </div>
  </div>
</template>

<script>
  // https://stackoverflow.com/questions/61169217
  import TwitterFeed from '@/components/TwitterFeed.vue';
  export default {
    name: 'AddTwitter',
    components: { TwitterFeed },
    data() {
      return {
        rerenderTweets: 0
      };
    },
    activated() {
      this.log('TwitterFeed activated()');
      // https://v3.vuejs.org/api/options-lifecycle-hooks.html#activated
      // Called when a kept-alive component is activated.
      this.rerenderTweets += 1;
      // Changing :key will force a re-rendering of TwitterFeed component
      // https://michaelnthiessen.com/force-re-render/
    },
    methods: {
      log(message) {                                      // eslint-disable-line
        if (process.env.NODE_ENV === 'development') {
          //console.log(message); // uncomment to show logs from this component
        }
      }
    }
  };
</script>

<style scoped>
  img#sponsor {
    /* Only needed for EMBO 2023 */
    vertical-align: middle;
    display: block;
    width: 55px;
    margin-top: 5px;
    margin-left: 0px;
    margin-bottom: 20px;
    padding: 0px;
    width: 70%;
    height: auto;
  }
  /* scoped = CSS limited to this component only */
  #main_content {
    /* https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */
    /* border-style: dotted; */
    overflow: hidden; /* https://stackoverflow.com/questions/2062258 */
  }
  #main_content {
    /* https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */
    display: inline-flex;
    /* display: inline; */
  }
  #main_content .left_column {
    /* width: 75%; */
    flex-grow: 0; /* don't grow */
    flex-shrink: 40; /* allow to shrink */
    flex-basis: 670px; /* Size of item before growing and shrinking happens */
    margin: 0px;
    /* padding: 0px 8px 0px 8px; */
  }
  #main_content .right_column {
    width: 200px;
    display: inline;
    flex-grow: 0; /* don't grow */
    flex-shrink: 0; /* don't shrink */
    flex-basis: 230px; /* Size of item before growing and shrinking happens */
    margin-left: 12px;
  }
  #main_content .left_column,
  #main_content .right_column {
    padding: 0px;
    /* padding: 0px 8px 0px 8px; */
  }
  #main_content a {
    display: none;
  } /* hide tweet button on large screens */
  /*@media only screen and (max-width: 640px) { */
  /* small screen breakpoint */
  .narrow-screen #main_content, .very-narrow-screen #main_content {
    /* https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */
    display: block;
  }
  .narrow-screen #main_content a, .very-narrow-screen #main_content a {
    /* reveal tweet button */
    display: auto;
    float: right;
    background-color: #04aa6d;
    border: none;
    border-radius: 4px;
    color: white;
    padding: 4px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
  }
  .narrow-screen #main_content .right_column, .very-narrow-screen #main_content .right_column {
    margin-left: 0px;
    /* display: none; */
  }

</style>
