<template>
  <AddTweets>
    <h3>About VIZBI</h3>
    <p>Visualisation is increasingly important in the life sciences as data grows rapidly in volume and complexity. The VIZBI initiative is an international conference series bringing together researchers developing and using computational visualisation to address a broad range of biological research areas; the conference also attracts participation from medical illustrators, graphic designers, and graphic artists. VIZBI is held annually, alternating between Europe and the USA.</p>
    <p><b><router-link to="2023">VIZBI 2023</router-link></b> - the 13th international meeting on Visualizing Biological Data, will take place March 29–31 at EMBL in Heidelberg, Germany. The meeting will be held in-person but will also have a virtual participation option.</p>
    <p>To help the broader research community benefit from VIZBI, <router-link to="Videos">videos</router-link> &amp; slides from talks at previous meetings are available, as are <router-link to="Posters">posters</router-link>.
      The VIZBI community have also produced a <a href="https://www.nature.com/nmeth/volumes/7/issues/3" target="_blank" rel="noopener">special issue of Nature Methods</a>, with reviews that provide practical guidelines
      on current methods and tools for visualizing biological data. In addition to the annual conference, we organize
      <router-link to="Plus">VIZBI showcase events</router-link> (formely 'VizbiPlus'), highlighting how visualization is being used to communicate hypotheses about complex
      biological phenomena.</p>
  </AddTweets>
</template>

<script>
  // https://stackoverflow.com/questions/61169217
  import AddTweets from '@/components/AddTweets.vue';
  export default {
    name: 'Home',
    components: {AddTweets}
  };
</script>
