<template>
  <!-- begin image src with "./" so it's imported as a vue 'static asset' -->
  <!-- https://cli.vuejs.org/guide/html-and-static-assets.html#static-assets-handling -->
  <a href="https://embl.org" target="_blank">
    <!-- <img id="primary" src="./img/embo-primary-black.svg" alt="EMBO" title="EMBO"> -->
    <img id="secondary" src="./img/embo-workshop.png" alt="EMBO" title="EMBO">
  </a>
</template>

<style scoped>
  img {
    /* May need some adjustments depending on aspect ratio etc */
    /* https://stackoverflow.com/questions/3029422 */
    vertical-align: baseline;
    margin-top: 8px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding: 0px;
  }
  #primary {
    max-width: 25%;
  }
  #secondary {
    max-width: 18%;
  }
  .very-narrow-screen #secondary {
    max-width: 12vw;
    align-items: center;
  }

</style>
