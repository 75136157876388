<!-- Copyright: Sean I. O'Donoghue -->

<template>
  <div />
</template>

<script>
  export default {
    name: 'Background',
    props: {
      selectedYear: {
        type: String,
        default: null
      }
    },
    watch: {
      // once $route.params.year becomes defined, this watch function is triggered
      //$route: function() {
      //  let previousSelecteYear = this.selectedYear;
      //  this.selectedYear = this.$route.params.year || this.$store.state.currentYear;
      //  if (this.selectedYear !== previousSelecteYear) {
      //    this.log('time to switch background');
      //    this.updateBackground(this.selectedYear);
      //  }
      //}
    },
    mounted() {
      if (this.selectedYear) {
        // only change background when selectedYear is not zero
        this.updateBackground(this.selectedYear);
        this.log(`Background mounted() with selectedYear = ${this.selectedYear}`);
      }
      this.log(`Background/mounted()/selectedYear = ${this.selectedYear}`);
    },
    methods: {
      log(message) {                                      // eslint-disable-line
        if (process.env.NODE_ENV === 'development') {
          //console.log(message); // uncomment to show logs from this component
        }
      },
      updateBackground(selectedYear) {
        let fileName = `@/assets/years/${this.selectedYear}/img/Hero/Background.jpg`;
        try {
          // first check if there is an image for this year
          // We now 'require' this backgrond image - 'hot-loading' it
          // Normally all 'requires' should only be done from the top level:
          // https://eslint.org/docs/rules/global-require
          // so this is flagged as an error - but the following line bypasses this
          // eslint-disable-next-line no-undef
          //const newImage = require(fileName);
          // eslint-disable-next-line
          document.body.style.backgroundImage = `url(${require(`@/assets/years/${this.selectedYear}/img/Hero/Background.jpg`)})`; // eslint-disable-next-line
          //document.body.style.backgroundImage = `url(${require(fileName)})`;
          //document.body.style.backgroundImage = fileName;
          //document.body.style.backgroundImage = `url(require(${fileName}))`;
          //document.body.style.backgroundImage = `url(${require(`${fileName}`)})`;
          this.log(`Changing to background image for ${selectedYear}`);
          this.log(`Background image for ${fileName}`);
          return fileName;
        } catch (e) {
          // if not, use the image for the current year
          // eslint-disable-next-line
          document.body.style.backgroundImage = `url(${require(`@/assets/years/${this.currentYear}/img/Hero/Background.jpg`)})`;
          document.body.style.backgroundImage = `url(${require(`@/assets/years/${this.currentYear}/img/Hero/Background.jpg`)})`;
          this.log(`Did not find background image for ${selectedYear}`);
          this.log(`Instead, will use background image for ${this.currentYear}`);
          return false;
        }
      }
    }
  };
</script>

<style>
  /* not 'scoped' = CSS applies to all components */
  body {
    /* background: #D5D5D5; */
    background-repeat: repeat-x;
    margin: 0;

    /* https://stackoverflow.com/questions/48240969 */
  }
</style>
