<!-- Copyright: Sean I. O'Donoghue -->

<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="overlay" ref="overlay" @click="hitItAndQuit" @keydown.esc="hitItAndQuit" tabindex="0">
    <!-- @click.stop="" prevents click on dialog -->
    <div ref="modal" :class="{'modal': true, 'fixedWidth': fixedWidth}" @click.stop="" >
      <div class="closeButton" @click.stop="hitItAndQuit" >
        <svg width="40" height="40" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <title>Close</title>
          <path d="M0 17.5C0 27.165 7.83502 35 17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5Z" fill="#AC003E" fill-opacity="1"/>
          <path d="M23.8002 11.1997L11.2002 23.7997" stroke="white" stroke-opacity="1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M23.8002 23.7997L11.2002 11.1997" stroke="white" stroke-opacity="1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
  // https://vuejsexamples.com/build-a-custom-modal-component-in-vue-js/
  export default {
    name: 'DialogModal',
    emits: ['hideDialog'],
    props: {
      fixedWidth: {type: Boolean, default: true},
    },
    mounted() {
      // freeze background - prevent vertical scrolling
      // https://stackoverflow.com/questions/56739111
      document.documentElement.style.overflow = 'hidden';
      // focus on model div so it can be dismissed with the escape key
      // https://stackoverflow.com/questions/49042667
      this.$refs.overlay.focus();
    },
    unmounted() {
      // unfreeze background - re-enable vertical scrolling
      document.documentElement.style.overflow = 'auto';
    },
    methods: {
      log(message) { // eslint-disable-line
        if (process.env.NODE_ENV === 'development') {
          console.log(message); // uncomment to show logs from this component
        }
      },
      hitItAndQuit() {
        this.log(`dialogModal/hitItAndQuit()`);
        this.$emit('hideDialog');
      }
    }
  }
</script>

<style scoped>
  .overlay {
    position: fixed;
    z-index: 100; /* solves z-issue with Dropdown/vue */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .modal {
    position: absolute;
    overflow-y: scroll; /* enable vertical scrolling for large text */
    top: 50%;
    left: 50%;
    text-align: center;
    transform:translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 1);;
    z-index: 1000;
    max-width: 90vw;
    max-height: 90vh;
    /* no idea why top needs to more 30px */
    padding: 33px 20px 20px 20px; /* top right bottom left */
    margin: 0px;
    border-radius: 20px;
  }
  .very-narrow-screen .modal, .narrow-screen .modal {
    width: 75vw;
  }
  .modal.fixedWidth {
    max-width: 300px;
  }
  .closeButton {
    position: absolute;
    z-index: 1000;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
</style>
