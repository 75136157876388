<!-- Copyright: Sean I. O'Donoghue -->

<template>
  <a class="safe-mail" :href="'mailto:' + finalEmail" :title="title" @click="mailClicked">{{ show ? finalValue : finalEmail }}</a>
</template>

<script>
  // From: https://github.com/2alheure/safe_mail
  export default {
    name: 'EmailEncoder',
    props: {
      email: {type: String, default: ''},
      title: {type: String, default: ''},
      show: {type: String, default: ''}
    },
    data() {
      return {
        emailAlreadyClicked: false
      };
    },
    computed: {
      finalEmail() {
        return this.reverse(this.email);
      },
      finalValue () {
        return this.reverse(this.show);
      }
    },
    methods: {
      reverse(input) {
        return input
          .split('')
          .reverse()
          .join('');
      },
      // Still to do - encode - https://stackoverflow.com/questions/5786483
      mailClicked(e) {
        if (this.emailAlreadyClicked) return;
        // Link already reversed so no need to compute anymore
        else {
          e.preventDefault;
          this.emailAlreadyClicked = true;
          e.target.href = 'mailto:' + this.email;
          e.target.click();
        }
      }
    }
  };
</script>

<style lang="postcss" scoped>
  a.safe-mail {
    unicode-bidi: bidi-override;
    direction: rtl;
  }
</style>
