<!-- Copyright: Sean I. O'Donoghue -->
<!-- From https://github.com/mikerodham/vue-dropdowns -->
<!-- Using the 'menu button' design pattern: -->
<!-- https://www.w3.org/WAI/ARIA/apg/patterns/menubutton/ -->

<template>
  <div class="container">
    <button v-if="selected && showTag"
      class="active" @click="select('', $event)">
        {{ selected }}
        <span class="cancel"> ✕ </span>
    </button>
    <button v-else @click="toggleMenu()">
      {{ placeholder }}
      <span class="caret" />
    </button>
    <transition name="slide-y">
      <ul v-if="showMenu" class="menu">
        <li v-for="option in options" :key="option" @click="select(option, $event)" :class="{'active': option === selected}" >
            {{ option }}
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
  import stringify from 'json-stringify-safe';
  export default {
    name: 'Dropdown',
    emits: ['update'], // https://stackoverflow.com/questions/64220737
    props: {
      placeholder: {type: String, default: ''},
      options: {type: Array, default: () => []},
      selected: {type: String, default: ''},  // option selected from parent
      showTag: {type: Boolean, default: true}, // switch to tag when selected
      closeOnOutsideClick: {type: Boolean, default: true},
    },
    data() {
      return {
        selectedMenuItem: '', // option selected in child
        showMenu: false
      }
    },
    mounted() { //   created
      if (this.closeOnOutsideClick) {
        document.addEventListener('click', this.documentClick);
      }
      this.log(`showTag = ${this.showTag}`);
    },
    beforeUnmount() { // unmounted
      document.removeEventListener('click', this.documentClick);
    },
    //updated() {
    // from old version
    // When toggling on and off button tags
    //},
    methods: {
      log(message) { // eslint-disable-line
        if (process.env.NODE_ENV === 'development') {
          //console.log(message); // uncomment to show logs from this component
        }
      },
      select(option, event) {
        console.log(`select(${option}, ${stringify(event)})`);
        this.selectedMenuItem = option;
        console.log(`this.selectedMenuItem = ${this.selectedMenuItem}`);
        this.showMenu = false; // hide the menu
        if (event) {
          console.log(`selection change driven by clicking on a menu item`);
          console.log(`sending selected option to parent`);
          this.$emit('update', option);
        }
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
        console.log(`toggleMenu: showMenu = ${this.showMenu}`);
        console.log(`options = ${stringify(this.options)}`);
      },
      documentClick(event) {
        this.log(`documentClick() called`);
        const {target} = event;
        this.log(`target = ${target}`);
        const {$el} = this; // 'this' is the first element in template
        this.log(`$el = ${$el}`);
        if (!$el.contains(target)) {
          this.log(`outside`);
          this.showMenu = false;
        } else {
          this.log(`inside`);
        }
      }
    }
  };
</script>

<style scoped>
  .container {
    display: inline-block;
    text-decoration: none;
    text-transform: none;
  }
  .container + .container {
    /* https://stackoverflow.com/questions/8184958 */
    margin-left: 0.8em;
  }
  button {
    position: relative;
    min-width: 110px;
    /*padding: 12px 36px 12px 12px; /* top right bottom left */
    padding: 0.3em 2.2em 0.3em 0.6em; /* top right bottom left */
    margin: 0px;
    border-radius: 0.5em;
    border: 0.5px solid rgba(94, 94, 94, 0.6);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    cursor: default;
    color: black;
    background-color: #fff;
    text-align: left;
  }
  .very-narrow-screen .container, .narrow-screen .container {
    vertical-align: 0px; /* shouldn't be needed but is! */
  }
  .menu {
    position: absolute;
    z-index: 2;
    float: left;
    min-width: 120px;
    padding: 5px 0;
    margin-top: 3px;
    list-style: none;
    /* font-size: 14px; */
    text-align: left;
    background-color: #fff;
    border: 1px solid #ccc;
    /* border-radius: 4px; */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
  }
  .menu>li {
    display: block;
    position: relative;
    margin: 0;
    list-style: none;
    padding: 2px 7px;
    clear: both;
    font-weight: normal;
    line-height: 1.6;
    color: #333333;
    white-space: nowrap;
    text-decoration: none;
    border-radius: 0px;
  }
  .menu>li:hover {
    color: black;
    background: #efefef;
    text-decoration: none;
  }
  .caret {
    position: absolute;
    width: 0;
    top: 0.7em;
    right: 0.8em;
    height: 0;
    margin-left: -0.4em;
    vertical-align: middle;
    border-top: 5px dashed;
    border-top: 5px solid \9;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
  span.cancel {
    position: absolute;
    top: 0.3em;
    right: 0.8em;
  }
  button:hover, button:focus, button:enabled:hover {
    color: black;
    background-color: #fff;
    border: 0.5px solid rgba(94, 94, 94, 1);
  }
  .america button:focus {
    border: 0.5px solid var(--text-color-america);
    box-shadow: 0 0 0 2px var(--text-color-america);
  }
  .europe button:focus {
    border: 0.5px solid var(--text-color-europe);
    box-shadow: 0 0 0 2px var(--text-color-europe);
  }
  .australia button:focus {
    border: 0.5px solid var(--text-color-australia);
    box-shadow: 0 0 0 2px var(--text-color-australia);
  }
  .active {
    border-radius: 13px; /* tag only */
  }
  .active, li.active, .active:enabled:hover {
    color: white;
    font-weight: 400;
    cursor: pointer;
  }
  .america .active, .america .active:enabled:hover  {
    background: var(--text-color-america);
    border: 0.5px solid var(--text-color-america);
  }
  .europe .active, .europe .active:enabled:hover  {
    background: var(--text-color-europe);
    border: 0.5px solid var(--text-color-europe);
  }
  .australia .active, .australia .active:enabled:hover  {
    background: var(--text-color-australia);
    border: 0.5px solid var(--text-color-australia);
  }
</style>
