<template>
  <div class="container">
    <a id="sponsor" href="https://embl.org" target="_blank" rel="noopener">
      <!-- <img id="primary" src="./img/embo-primary-black.svg" alt="EMBO" title="EMBO"> -->
      <img src="./Sponsors/img/embo-primary-black.svg" alt="EMBO" title="EMBO">
    </a>
    <p>The EMBO Workshop on 'Visualizing Biological Data' (VIZBI 2023) takes place March 28&ndash;31 at EMBL in Heidelberg, Germany. This will be the 13th international meeting on this topic. While primarily an in-person event, there is also an option for online participation.</p>
    <HeroImage :downloads="true" credit='EMBL 2023 | Aleksandra Krolik' /> <!-- true  false-->
    <p>VIZBI 2023 will feature talks from 21 world-leading researchers showcasing visualizations transforming how life scientists view data, and driving key advances in molecular biology, systems biology, biomedical science, and ecology.</p>
    <p style="hyphens: auto !important;">There are also two satellite events: (1) a pre-workshop <router-link to="/2023/Masterclass">Masterclass</router-link> provides opportunities for data visualization training from leading experts, and (2) a post-workshop evening <router-link to="/2023/Showcase">Showcase</router-link> event highlights life science communication in film.</p>
    <p>The workshop brings together a diverse community, including bioinformaticians, data scientists, computer scientists, and experimentalists, as well as medical illustrators, graphic designers, and graphic artists.</p>
    <!--<p>All participants will have the opportunity to present a <a href="Posters/">poster and lightning talk</a> describing their work.</p> -->
    <p>All participants will have the opportunity to present a <router-link to="/2023/Posters">poster</router-link> and <router-link :to="{ name: 'Posters', hash: '#Lightning' }">lightning talk</router-link> describing their work. To see examples
      from previous VIZBIs, please take a look at the <router-link to="/Posters/2021">VIZBI poster collection</router-link>.</p>
    <!--				<p>If you are interested in promoting your organization or professional society, please contact us to discuss <a href="Sponsors/">sponsorship options</a>.</p> -->
    <p>
      <!--More details will be announced over the next few weeks. -->To receive updates about VIZBI, we encourage you to join the <router-link to="/Mail">VIZBI mailing list</router-link>.
    </p>
    <!-- <a href="http://www.iscb.org/" rel="nofollow"><img src="Sponsors/img/ISCB.jpg" alt="ISCB" title="ISCB" align="absmiddle" /></a> -->
    <h3>Key Dates</h3>
    <table>
      <colgroup>
        <col span="1" style="width: 40%;">
        <col span="1" style="width: 60%;">
      </colgroup>
      <tr>
        <td><s><b>Jan 15</b></s> <b>Jan 22</b></td>
        <td>Updated deadline for <router-link to="/2023/Registration#waivers">financial assistance</router-link></td>
      </tr>
      <tr>
        <td><b>Feb 14</b> <span>(11pm PST):</span></td>
        <td>Deadline for <router-link to="/2023/Registration">on-site registration<sup>*</sup></router-link></td>
      </tr>
      <tr>
        <td><b>Mar 6</b> <span>(11pm PST):</span></td>
        <td>Deadline for <router-link to="/2023/Posters#submissions">Poster</router-link> and <router-link to="/2023/Posters#art">Art &amp; Biology</router-link> uploads</td>
      </tr>
      <tr>
        <td><b>Mar 21</b> <span>(11pm PST):</span></td>
        <td>Deadline for <router-link to="/2023/Registration">virtual registration<sup>*</sup></router-link></td>
      </tr>
      <tr>
        <td><b>Mar 27-28</b>:</td>
        <td><router-link to="/2023/Masterclass">Data Visualization Masterclass</router-link></td>
      </tr>
      <tr>
        <td><b>Mar 28-31</b>:</td>
        <td><router-link to="/2023/Program">VIZBI workshop</router-link></td>
      </tr>
    </table>
    <p><sup>*</sup><em>After Feb 24, <router-link to="/2023/Contact">contact us by email</router-link> to ask about late registration.</em></p>
  </div>
</template>

<script>
  import HeroImage from '@/components/HeroImage.vue';
  export default {
    components: { HeroImage }
  };
</script>

<style scoped>
  /* Container holding the image and the text */
  .container {
    position: relative;
  }
  #sponsor img {
    display: none;
    /* May need some adjustments depending on aspect ratio etc */
    /* https://stackoverflow.com/questions/3029422 */
    vertical-align: middle;
    float: right;
    position: absolute;
    /*position: relative; /* allows for location changes */
    top: -55px;
    right: 8px;
    height: 45px;
    margin-top: 5px;
    margin-left: 0px;
    padding: 0px;
  }
  .narrow-screen #sponsor img {
    display: block;
    top: -50px;
    height: 35px;
  }
  .very-narrow-screen #sponsor img {
    display: block;
    top: -47px;
    height: 30px;
  }
  .very-narrow-screen table {
    /* width: 100%; Better? */
    font-size: 13px;
  }
  b {
    font-weight: 500;
  }
  span {
    font-weight: 200;
  }
  td {
   vertical-align: top;
  }
</style>
