<!-- Copyright: Sean I. O'Donoghue -->

<template>
  <!-- https://www.npmjs.com/package/vue-resize-text -->
  <ul>
    <li v-for="view in Object.keys(views)" :key="view">
      <router-link
        :to="'/' + view.replace('Home', '')"
        :class="{ selected: views[view].selected }"
      >
        {{ views[view].showAs }}
      </router-link>
    </li>
    <li>
      <router-link
        :to="'/' + latestVIZBI.year"
        :class="{ selected: latestVIZBI.selected }"
      >
        {{ latestVIZBI.year }}
      </router-link>
    </li>
    <li><Burger /></li>
  </ul>
  <Sidebar :views="views" />
</template>

<script>
  import Burger from "@/components/Burger.vue";
  import Sidebar from "@/components/Sidebar.vue";
  export default {
    name: 'NavigationPrimary',
    components: {
      Burger,
      Sidebar
    },
    props: {
      color: {type: String, default: '#FFF'}
    },
    data() {
      return {
        views: {
          'Home': {showAs: 'About', selected: true},
          'Blog': {showAs: 'Blog', selected: false},
          'Posters': {showAs: 'Posters', selected: false},
          'Videos': {showAs: 'Videos', selected: false},
          //'Mail': {showAs: 'Mailing List', selected: false}
        },
        latestVIZBI: {
          year: this.$store.state.currentYear,
          selected: false
        }
      };
    },
    watch: {
      $route() {
        this.updateSelectionState();
      }
    },
    created() {
      this.updateSelectionState();
    },
    methods: {
      log(message) {                                      // eslint-disable-line
        if (process.env.NODE_ENV === 'development') {
          //console.log(message); // uncomment to show logs from this component
        }
      },
      updateSelectionState() {
        for (var view of Object.keys(this.views)) {
          if (
            this.$route.name && // ! this.$route.params.year
            this.$route.name === view
          ) {
            // highlight the currently selected view
            this.views[view].selected = true;
            this.log(`Selected view: ${view}`);
          } else {
            // ensure all other views are not highlighted
            this.views[view].selected = false;
          }
        }
        if (
          this.$route.params.year &&
          this.$route.params.year === this.latestVIZBI.year
        ) {
          // highlight the currently selected year
          this.latestVIZBI.selected = true;
          this.log(`Current year is selected (${this.latestVIZBI.year})`);
        } else {
          // ensure current year is not highlighted
          this.latestVIZBI.selected = false;
        }
      }
    }
  };
</script>

<style scoped>
  /* scoped = CSS limit to this component only */
  ul {
    color: #fff;
    flex-basis: 35%;
    /* Was; display: block;     display: flex; */
    list-style-type: disc;
    margin-top: 2px;
    margin-bottom: 28px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px;
    text-align: right;
    text-shadow: rgb(0, 0, 0) 0px 0px 6px;
  }
  .narrow-screen ul, .very-narrow-screen ul {
    /* hide burger bars on wide screen devices */
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .narrow-screen ul li a, .very-narrow-screen ul li a {
    /* hide burger bars on wide screen devices */
    display: none;
  }
  li {
    color: #fff;
    display: list-item;
    /* was 1.5em - https://css-tricks.com/confused-rem-em/*/
    line-height: 1.3rem;
    /* was: 1.5em */
    font-weight: 700;
    letter-spacing: 0.03em;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    text-align: right;
    text-shadow: rgb(0, 0, 0) 0px 0px 6px;
  }

  /* Mobile first - very-narrow-screen */
  .very-narrow-screen li {
    font-size: 13px; /* was: 0.8rem 18px */
  }
  /* narrow-screen */
  .narrow-screen li {
    font-size: 14px; /* was: 0.8rem 18px */
  }
  /* medium-screen */
  .medium-screen li {
    font-size: 15px; /* was: 0.8rem 18px */
  }
  /* wide-screen */
  .wide-screen li {
    font-size: 16px; /* was: 0.8rem 18px */
  }

  li.archivewrapper {
    float: right;
    /* padding-right: 12px; works but for all years; need to add archive */
  }
  li.archivewrapper + li.archivewrapper::after {
    /* https://stackoverflow.com/questions/9171699 */
    content: " | ";
  }
  /* color without hover */
  a {
    color: #fff;
    text-decoration: none;
  }
  a:hover {
    color: #D3D3D3;
    text-decoration: none;
  }
  /* color while hovering or selected */
  .america a.selected  {
    color: var(--text-color-america);
  }
  .europe a.selected {
    color: var(--text-color-europe);
  }
  .australia a.selected {
    color: var(--text-color-australia);
  }
</style>
