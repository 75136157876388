<template>
  <h2>Join VIZBI Mailing List</h2>
  <ol>
    <li>Sign up below to receive email notification of major announcements related to VIZBI.</li>
    <div id="mail-form" class="_form_13" />
    <li>Add <a href="mailto:contact@vizbi.org">contact@vizbi.org</a> to your address book to ensure VIZBI emails never get sent
      to spam.</li>
    <li style="margin-top: 20px;">For more frequent VIZBI news, follow the <a href="https://vizbi.org/blog/">VIZBI blog</a> using <a href="https://vizbi.org/blog/feed/">RSS</a>,
      <a href="http://twitter.com/vizbi">Twitter</a>, <a href="http://friendfeed.com/vizbi">FriendFeed</a>, or <a href="http://www.facebook.com/pages/VIZBI/103403666363639">Facebook</a>.
    </li>
  </ol>
</template>

<script>
  // https://cse.google.com/
  export default {
    name: 'Mail',
    // props: ['site'], - used in old (not working) version
    beforeRouteLeave () {
      // this.observer.disconnect(); // breaks the router; no idea why was here
    },
    data() {
      return {
        text: '',
        observer: {}
      };
    },
    async beforeMount() {
      this.log('Mail beforeMount()');
      // https://stackoverflow.com/questions/45047126
      let recaptchaScript = document.createElement('script');
      recaptchaScript.async = true;
      // https://stackoverflow.com/questions/3408805
      //recaptchaScript.setAttribute('async', true);
      recaptchaScript.setAttribute('src', 'https://masteryevents.activehosted.com/f/embed.php?id=13');
      document.head.appendChild(recaptchaScript);
      var trackcmp_email = '';
      var trackcmp = document.createElement('script');
      trackcmp.async = true;
      trackcmp.type = 'text/javascript';
      trackcmp.src =
        '//trackcmp.net/visit?actid=251984889&e=' +
        encodeURIComponent(trackcmp_email) +
        '&r=' +
        encodeURIComponent(document.referrer) +
        '&u=' +
        encodeURIComponent(window.location.href);
      var trackcmp_s = document.getElementsByTagName('script');
      if (trackcmp_s.length) {
        trackcmp_s[0].parentNode.appendChild(trackcmp);
      } else {
        var trackcmp_h = document.getElementsByTagName('head');
        trackcmp_h.length && trackcmp_h[0].appendChild(trackcmp);
      }
    },
    mounted() {
      this.log('Mail mounted()');
      // clear activecampaign console messages after waiting a bit
      //setTimeout(console.clear, 1000);
    },
    methods: {
      log(message) {                                      // eslint-disable-line
        if (process.env.NODE_ENV === 'development') {
          //console.log(message); // uncomment to show logs from this component
        }
      }
    }
  };
</script>

<style scoped>
  ._form-thank-you {
    color: red;
  }
</style>
