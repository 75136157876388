<!-- Copyright: Sean I. O'Donoghue -->

<template>
  <figure v-if="heroImage && downloads">
    <a href='Poster.jpg'>
      <img
        :src="heroImage"
        alt="Hero image"
        title='Click to download low-res poster'
      >
    </a>
    <figcaption>
      <small>Download:
        <a href='Poster.jpg'>Poster.jpg</a> |
        <a href='Poster.pdf'>Poster.pdf</a> |
        <a href='Assets.zip'>Assets.zip</a>
      </small>
      <small v-if="credit" class='credit'>&#169; {{ credit }}</small>
    </figcaption>
  </figure>
  <hr v-if="heroImage && downloads">
  <figure v-else-if="heroImage">
    <img
      :src="heroImage"
      alt="Hero image"
    >
    <small v-if="credit" class='credit'>&#169; {{ credit }}</small>
    <br />
  </figure>
</template>

<script>
  export default {
    name: 'HeroImage',
    props: {
      credit: {type: String, default: ''},
      downloads: {type: Boolean, default: false},
      // if true, links will be added to download poster and assets.zip
      file: {type: String, default: ''}
    },
    data() {
      return {
        heroImage: '',
        lowRes: '',
        hiRes: '',
        assets: ''
      }
    },
    mounted() {
      if (! this.$route.params.year) {
        return; // early exit
      }
      if (this.file) {
        // if file is defined explicitly
        this.heroImage = require(`@/assets/years/${this.file}`);
      } else {
        let year = this.$route.params.year;
        this.log(`year = ${year}`);
        this.heroImage = require(`@/assets/years/${year}/img/Hero/Medium.jpg`);
        this.log(`heroImage = ${this.heroImage}`);
      }
    },
    methods: {
      log(message) {                                      // eslint-disable-line
        if (process.env.NODE_ENV === 'development') {
          //console.log(message); // uncomment to show logs from this component
        }
      },
    }
  };
</script>

<style scoped>
  /* scoped = CSS limit to this component only */
  figure {
    margin: 0px;
  }
  img {
    margin: auto;
    width: 100%;
  }
  figcaption {
    margin-top: 4px;
    font-size: 13px;
    font-weight: 300;
  }
  small {
    float: left;
    margin-bottom: 8px;
  }
  small.credit {
    float: right;
  }
  hr {
    color: #d3d3d3; /* old IE */
    background-color: #d3d3d3; /* Modern Browsers */
    border: none;
    height: 0.5px;
  }
  br, hr {
    clear: both; /* clear float */
  }
</style>
