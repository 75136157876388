<!-- Copyright: Sean I. O'Donoghue -->

<template>
    <Button
      ref="mailButton"
      :selected="selected"
      @click="$router.push('/Mail')"
      title="Click to join VIZBI mailing list">
      Join Mailing List
    </Button>
</template>

<script>
  import Button from '/src/components/Button';
  export default {
    name: 'ButtonMail',
    components: {Button},
    data() {
      return {
        selected: false
      }
    },
    watch: {
      $route() {
        this.updateSelectionState();
      }
    },
    mounted() {
      this.log('MailButton mounted');
      this.updateSelectionState();
    },
    methods: {
      log(message) {                               // eslint-disable-line
        if (process.env.NODE_ENV === 'development') {
          //console.log(message); // uncomment to show logs from this component
        }
      },
      updateSelectionState() {
        this.log(`Called updateSelectionState()`);
        if (
          this.$route.name &&
          this.$route.name === 'Mail'
        ) {
          // set button to autofocus
          // https://michaelnthiessen.com/set-focus-on-input-vue
          //this.$refs['mailButton'].focus();
          this.selected = true;
          this.log(`Current page is Mail view`);
        } else {
          // turn off autofocus
          this.selected = false;
          //this.$refs['mailButton'].blur();
        }
      }
    }
  };
</script>

<style scoped>
  Button {
    /* May need some adjustments depending on aspect ratio etc */
    /* https://stackoverflow.com/questions/3029422 */
    position: absolute;
    /*position: relative; /* allows for location changes */
    top: 12px;
    left: 50%;
    margin-left: -70px;
    opacity: 0.45;
  }
  .narrow-screen button, .very-narrow-screen button {
    /* turn off mailing list button */
    display: none
  }
</style>
