<!-- Copyright: Sean I. O'Donoghue -->

<template>
  <span v-if="selectedYear">
    <!-- only render once the selectedYear changes to non-zero -->
    <!-- https://stackoverflow.com/questions/40898440 -->
    <keep-alive include="mainSponsorFunction">
      <component :is="mainSponsorFunction" :key="selectedYear" />
    </keep-alive>
  </span>
</template>

<script>
  export default {
    name: 'MainSponsors',
    props: {
      // selectedYear is passed down from App.vue
      selectedYear: {
        default: 0,
        type: [Number, String]
      }
    },
    data() {
      return {
        mainSponsorPath: ''
      };
    },
    computed: {
      mainSponsorFunction() {
        this.log(`computed > mainSponsor`);
        try {
          return require(`@/assets/years/${this.mainSponsorPath}`).default;
        } catch (error) {
          this.log(`Not found: ${this.mainSponsorPath} - ${error}`);
          return {template: '<p>No main sponsor found</p>'};
        }
      }
    },
    watch: {
      selectedYear() {
        this.mainSponsorPath = this.selectedYear + '/Sponsors/MainSponsor.vue';
        this.log('watch: ' + this.mainSponsorPath);
      }
    },
    created() {
      this.mainSponsorPath = this.selectedYear + '/Sponsors/MainSponsor.vue';
      this.log('created(): ' + this.mainSponsorPath);
    },
    methods: {
      log(message) {                                      // eslint-disable-line
        if (process.env.NODE_ENV === 'development') {
          //console.log(message); // uncomment to show logs from this component
        }
      },
    }
  };
</script>

<style scoped>
  span {
    margin-top: 8px;
    /* https://stackoverflow.com/questions/3029422 */
    /* max-width: 35%; */
    /* max-height: 50%; */
    vertical-align: middle;
  }
  .narrow-screen span {
    margin-top: 0px;
    align-items: center;
  }
</style>
