<template>
  <div>
    <!-- begin image src with "./" so it's imported as a vue 'static asset' -->
    <!-- https://cli.vuejs.org/guide/html-and-static-assets.html#static-assets-handling -->
    <a href="https://embl.org" target="_blank">
      <img src="./img/embl.svg" alt="EMBL" title="EMBL">
    </a>
  </div>
</template>

<style scoped>
  img {
    /* May need some adjustments depending on aspect ratio etc */
    /* https://stackoverflow.com/questions/3029422 */
    max-width: 17%;
    vertical-align: middle;
  }
</style>
