<!-- Copyright: Sean I. O'Donoghue -->

<template>
  <button v-if="label" :class="{'selected': selected === label}" @click="updateParent" ref="tag">
    <span>{{ label }}</span>
    <span v-if="selected === label" class="cancel">  ✕ </span>
  </button>
</template>

<script>
  export default {
    name: 'Tag',
    emits: ['update'], // https://stackoverflow.com/questions/64220737
    props: {
      label: {type: String, default: ''},
      selected: {type: String, default: ''}
    },
    methods: {
      log(message) { // eslint-disable-line
        if (process.env.NODE_ENV === 'development') {
          console.log(message); // uncomment to show logs from this component
        }
      },
      updateParent() {
        let response = ''; // response if tag was already selected
        if (this.selected && this.selected === this.label) {
          // if something was already selected, and it was this label
          // then lose focus on this tag
          //event.target.blur();
          this.$refs.tag.blur();
          // https://stackoverflow.com/questions/61776432
        } else {
          // if tag was not already selected, click sends back the label
          response = this.label;
        }
        this.log(`updateParent: label = ${this.label}, response = ${response}`);
        this.$emit('update', response); // send response to parent
      }
    }
  };
</script>

<style scoped>
  /* scoped = CSS limit to this component only */
  button {
    /* Longer tag names break into two lines for higher values */
    padding: 0.4em;
    margin-top: 0.25em;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    line-height: 10px;
    border: none;
    color: white;
    background: #a7a7a7;
    border-radius: 0.35em;
    text-shadow: none;
    box-shadow: none;
    display: inline;
  }
  button + button {
    margin-left: 0.4em;
  }
  button.selected {
    color: white;
    opacity: 1;
  }
  .america button.selected {
    background-color: var(--text-color-america);
  }
  .europe button.selected {
    background-color: var(--text-color-europe);
  }
  .australia button.selected {
    background-color: var(--text-color-australia);
  }
  button:hover {
    background-color: #D5D5D5;
  }
  /* https://stackoverflow.com/questions/24923647 */
  a:hover:not(:focus) {
    color: #a7a7a7; /* when it has focus but not hover, back to normal */
  }
  span.cancel {
    padding-left: 3px;
  }
</style>
