<!-- Copyright: Sean I. O'Donoghue -->

<template>
  <span v-if="to && address[to]" :class="{ outstanding: outstanding }">
    <EmailEncoder :email="address[to]['recipient'] + '&#x40;' + address[to]['site'] + '.' + address[to]['domain']" :title="address[to]['title']" :show="show" />
  </span>
</template>

<script>
  import EmailEncoder from '@/components/EmailEncoder.vue';
  export default {
    name: 'Email',
    components: { EmailEncoder },
    props: {
      to: {type: String, default: '', required: true},
      outstanding: {type: Boolean, default: false},
      show: {type: String, default: ''},
    },
    data() {
      return {
        address: {
          'embl-events': {
            'recipient': 'events',
            'site': 'embl',
            'domain': 'de'
          },
          'masterclass': {
            'recipient': 'masterclass',
            'site': 'vizbi',
            'domain': 'org'
          },
          'nathalie-sneider': {
            'recipient': 'nathalie.sneider',
            'site': 'embl',
            'domain': 'de'
          },
          'vizbi-contact': {
              'recipient': 'contact',
              'site': 'vizbi',
              'domain': 'org'
          },
          'vizbi-registration': {
            'recipient': 'registration',
            'site': 'vizbi',
            'domain': 'org'
          },
          'vizbi-workshop': {
            'recipient': 'workshop',
            'site': 'vizbi',
            'domain': 'org'
          },
          'christian-stolte': {
            'recipient': 'christian.stolte',
            'site': 'gmail',
            'domain': 'com'
          },
        }
      };
    }
  };
</script>

<style scoped>
  .outstanding {
    font-weight: 700;
    font-size: 1.2em;
    margin-left: 24px;
    display: inline;
  }
</style>
