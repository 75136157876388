import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// import history from 'connect-history-api-fallback';
// 'history' fixes 404 issue on GitHub pages > everything to index.html
// https://github.com/bripkens/connect-history-api-fallback

createApp(App).use(router).use(store).mount('#app');
//createApp(App).use(history).use(router).use(store).mount('#app');
