<template>
  <h2>VIZBI admin</h2>
  <Authorize v-if="login_required" />
  <Transition appear>
    <section v-if="visible">Option A</section>
    <section v-else>Option B</section>
  </Transition>
</template>

<script>
  import Authorize from "/src/components/Authorize";
  //import stringify from 'json-stringify-safe';
  export default {
    name: "Video",
    components: {
      Authorize
    },
    data() {
      return {
        login_required: false,
      };
    },
    methods: {
      log(message) { // eslint-disable-line
        console.log(message); // uncomment to show logs from this component
        if (process.env.NODE_ENV === 'development') {
          //console.log(message); // uncomment to show logs from this component
        }
      },
    }
  };
</script>

<style scoped>
  section {
    background-color: gainsboro;
    border: 1px solid #BDBDBD;
    margin-bottom: 16px;
  }

  .v-enter-from {
    opacity: 0;
  }

  .v-enter-to {
    opacity: 1;
  }

  .v-enter-active {
    transition: all 1.5s ease;
  }

  .v-leave-from {
    opacity: 1;
  }

  .v-leave-to {
    opacity: 0;
  }

  .v-leave-active {
    transition: all 0.5s ease;
  }

</style>
