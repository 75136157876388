<template>
  <!-- <h4>Visual storytelling - from ecosystems to biomolecules</h4> -->
  <!-- <h4>Using animation and cinematography to explain the living world</h4> -->
  <h4>Animating and photographing the living world</h4>
  <p>The VIZBI Showcase is an in-person-only evening event that places following the <router-link to="/2023">VIZBI 2023</router-link> workshop. This satellite meeting will feature a conversation between two award winning science communicators — biomedical animator Drew Berry and natural history filmmaker Martin Dohrn — along with selected screenings of their recent work.</p>
  <HeroImage file='2023/Showcase/img/banner.jpeg' credit='Martin Dohrn &amp; Drew Berry' />
  <p>Drew and Martin will discuss the challenges of communicating the complexity of living organisms — across a broad range of scales. This will be followed by a panel discussion and Q&amp;A on the role of film in educating and inspiring audiences with discoveries from the life sciences.</p>
  <p>This event takes place directly following the VIZBI 2023 workshop on ‘Visualizing Biological Data’. The event will be free, and all VIZBI participants are invited, as are interested scientists from the local research community. <router-link to="/2023/Contact">Contact us</router-link> if you are would like to know more.</p>
</template>

<script>
import HeroImage from '@/components/HeroImage';
export default ({
    name: 'Index',
    components: {HeroImage},
  });
</script>

<style scoped>
th {
    padding-right: 12px;
    color: #999;
    font-size: 80%;
    font-weight: 900;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }

  table {
    border-collapse: collapse;
    margin-bottom: 1em;
    width: 100%;
  }

  tr {
    vertical-align: top;
  }

  td,
  th {
    padding: 6px;
  }

  tr:nth-child(even) {
    background: #EEE;
  }

  p,
  ol,
  ul {
    line-height: 1.5em;
    min-width: 150px;
  }
</style>
