<!-- Copyright: Sean I. O'Donoghue -->

<template>
  <!-- re-run get_meta_data if login is successful -->
  <Authorize
    v-if="login_required || registration_required"
    :showcase="showcase"
    @success="get_meta_data"
  />
  <figure ref="mediaWrapper">
    <Transition mode="out-in" appear>
      <vue-vimeo-player v-if="lookup_value"
        :video-id="lookup_value"
        :options="{ responsive: true }"
        :player-height="320"
        :player-width="media_width"
      />
      <img v-else-if="thumbnails['base_link'] && thumbnails['sizes'][3]"
        :src="thumbnails['base_link'] + thumbnails['sizes'][3]"
        style="width: 100%"
      >
      <p v-else>No video or thumbnail available</p>
    </Transition>
    <figcaption v-if="showcases && showcase && showcases[showcase]">
      <h4>{{
        showcases[showcase]['videos'][$route.params.vimeoHash].name
      }}</h4>
      <p>{{
        showcases[showcase]['videos'][$route.params.vimeoHash].description
      }}</p>
    </figcaption>
  </figure>
</template>

<script>
  import {vueVimeoPlayer} from 'vue-vimeo-player';
  import Authorize from "/src/components/Authorize";
  import stringify from 'json-stringify-safe';
  export default {
    name: "Video",
    components: {
      Authorize,
      vueVimeoPlayer // https://stackoverflow.com/questions/55006789
    },
    props: {
      showcases: {
        type: Object,
        default: () => {}
      },
      thumbnails: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        login_required: false,
        registration_required: false,
        privacy: '',
        lookup_value: '',
        media_width: '640px',
        showcase: ''
      };
    },
    watch: {
      route: async function() {
        await this.get_meta_data();
      }
    },
    created() {
      window.addEventListener("resize", this.matchWidth);
    },
    async mounted() {
      this.log('Video mounted()');
      this.matchWidth(); // check initial window width
      this.log(`vimeoHash initially set to ${this.$route.params.vimeoHash}`);
      await this.get_meta_data();
      if (this.thumbnails && this.thumbnails['base_link']) {
        this.log(`thumbnails = ${this.thumbnails['base_link']}`);
      }
    },
    unmounted() {
      window.removeEventListener("resize", this.matchWidth);
    },
    methods: {
      log(message) { // eslint-disable-line
        //console.log(message); // uncomment to show logs from this component
        if (process.env.NODE_ENV === 'development') {
          //console.log(message); // uncomment to show logs from this component
        }
      },
      matchWidth() {
        // https://stackoverflow.com/questions/44948714
        this.log(`media_width = ${this.$refs.mediaWrapper.clientWidth}`);
        // call whenever the window is resized
        this.media_width = this.$refs.mediaWrapper.clientWidth;
      },
      async get_meta_data() {
        if (! this.$route.params.vimeoHash) {
          return;
        }
        let vimeoHash = this.$route.params.vimeoHash
        this.log(`get_meta_data(${vimeoHash})`);
        let response = await this.get_lookup_value(vimeoHash);
        if (!response) {
          return console.error(`No response from backend server.`);
        }
        this.log(`get_lookup_value returned`);
        if (!response.headers) {
          return console.error(`lookup response has no headers.`);
        }
        this.log(`lookup_value has headers`);
        this.lookup_value = await response.headers.get('lookup_value');
        let action_required = await response.json();
        if (action_required.showcase) {
          this.showcase = action_required.showcase;
        } else {
          this.log(`No event found for this video`);
        }
        if (this.lookup_value) {
          this.log(`Found lookup_value = ${stringify(this.lookup_value)}`);
          if (action_required && action_required.loginToken) {
            // update loginToken if it has expired
            window.localStorage.loginToken = action_required.loginToken;
            window.localStorage.events = action_required.events;
          }
          return this.lookup_value;
        } else {
          this.log(`No lookup_value returned`);
          if (action_required.result === 'login_required') {
            this.log(`Login required to access this video`);
            this.login_required = true; // show Login component
            this.registration_required = false; // just in case was re-set
          } else if (action_required.result === 'registration_required') {
            console.warn(`Registration required to access this video`);
            this.registration_required = true; // show Login component
            this.login_required = false; // just in case was re-set
          } else if (action_required.result === 'invalid_vimeo_hash') {
            this.log(`Invalid vimeo hash; return to /Videos page`);
            return this.$router.push('/Videos');
          } else if (action_required.result === 'invalid_login_token') {
            console.warn(`Stored loginToken is invalid, so remove it`);
            this.login_required = true; // show Login component
            this.registration_required = false; // just in case was re-set
            return window.localStorage.loginToken = '';
          } else {
            console.warn(`Unknown status=${status}; response= ${stringify(response)}`);
          }
        }
      },
      async get_lookup_value(vimeoHash) {
        this.log(`get_lookup_value(${vimeoHash})`);
        try {
          const response = await fetch(
            `${process.env.VUE_APP_API}/videos/lookup/${vimeoHash}`, {
              method: 'GET',
              //cache: 'force-cache', // needed for client cache to work?
              headers: {
                //'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.loginToken}`
              },
            }
          );
          if (!response.ok) {
            // https://dmitripavlutin.com/javascript-fetch-async-await/
            throw new Error("HTTP error " + response.status);
          }
          return response;
        } catch (error) {
          console.error(`Problem accessing vizbi backend: ${error}`);
        }
      }
    }
  };
</script>

<style scoped>
  .embargo {
    color: #929292;
    font-style: italic;
    font-weight: 300;
  }

  figure {
    margin: 0px;
  }

  #thumbnail-large {
    cursor: default;
  }

  .v-enter-from, .v-leave-to {
    opacity: 0;
    /* transform: scale(0.6); */
  }

  .v-enter-active, .v-leave-active {
    transition: all 0.4s ease;
  }

  /* everything transitions */
.everything-enter-from, .everything-leave-to {
  opacity: 0;
}

.everything-enter-active {
  transition: all 0.4s ease;
}

.everything-leave-active {
  transition: all 0.4s ease;
  position: absolute;
  /* for move transition after item leaves */
}

.everything-move {
  transition: all 1s ease;
}


</style>
